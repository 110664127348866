import {IdToken} from "@auth0/auth0-spa-js";

const SendUnauthenticatedRpc = (method: string, request: Uint8Array): Promise<Uint8Array> => {

  return new Promise((resolve, reject) => {
    InternalSendRpc(undefined, method, request)
        .then(response => {
          resolve(response);
        })
        .catch(reason => {
          reject(reason);
        });
  });
}

const SendRpc = (tokenFetcher: () => Promise<IdToken | undefined>,
                 method: string, request: Uint8Array): Promise<Uint8Array> => {

  return new Promise((resolve, reject) => {

    tokenFetcher().then(token => {

      InternalSendRpc(token?.__raw, method, request)
          .then(response => {
            resolve(response);
          })
          .catch(reason => {
            reject(reason);
          });
    }).catch(e => {
      reject(e);
    })
  });
}

const InternalSendRpc = (token: string | null | undefined, method: string, request: Uint8Array): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    
    console.log("[SendRpc] sending " + method + " request");

    let url = `http://localhost:8080/action/${method}`;

    if (window.location.host.endsWith("thymebook.com")) {
      url = `https://admin-1096702705153.us-central1.run.app/action/${method}`
    }

    fetch(url, {
      headers: {
        'Authentication': token ?? ''
      },
      method: 'POST',
      body: request
    })
        .then(response => {
          console.log('[SendRpc] received ' + method + ' response with status ' + response.status)
          if (response.status != 200) {
            reject('Status code ' + response.status);
            return;
          }

          response.arrayBuffer()
              .then(buffer => {
                resolve(new Uint8Array(buffer));
              })
              .catch(error => {
                console.warn("[SendRpc] error reading response body" + method, error);
                reject(error);
              });
        })
        .catch(e => {
          console.warn('[SendRpc] error in http layer ' + method, e);
          reject(e.toString());
        });
  });
};

export {SendRpc, SendUnauthenticatedRpc};
