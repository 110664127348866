import React from 'react';
import {Outlet, Routes} from 'react-router';
import {useAuth0} from '@auth0/auth0-react';
import './AdminHome.css';
import {LeftNavIcon} from "./LeftNavIcon";
import {Route} from "react-router-dom";
import {UsersTab} from "./Users/UsersTab";
import {ProvidersTab} from "./Providers/ProvidersTab";
import {SettingsTab} from "./Settings/SettingsTab";
import {ReviewsTab} from "./Reviews/ReviewsTab";

/**
 * This is the root of all admin pages. The user is assumed to be logged in here since this
 * is a child of the main App.tsx which enforces login.
 *
 * This has a react router with all the subpages underneath it.  Note that since is really
 * the home page of the app and lives at the root path (/) there isn't any router above this.
 */
export const AdminHome = () => {

  const {user} = useAuth0();

  // Loaded and authenticated.
  return (
      <div style={{flex: 1, display: 'flex', flexDirection: 'row', width: '100%'}}>

        {/* The left nav bar containing all the tools */}
        <div style={{display: 'flex', flexDirection: 'column', flex: 0, backgroundColor: '#eee'}}>
          <LeftNavIcon link={'/'}>Home</LeftNavIcon>
          <LeftNavIcon link={'/users/'}>Users</LeftNavIcon>
          <LeftNavIcon link={'/providers/'}>Providers</LeftNavIcon>
          <LeftNavIcon link={'/reviews/'}>Reviews</LeftNavIcon>
          <LeftNavIcon link={'/settings/'}>Settings</LeftNavIcon>
          <br/>
        </div>

        {/* The main body of the tool- And the right which takes up the most of the page*/}
        <div style={{
          display: 'flex', flexDirection: 'column',
          flex: 1, textAlign: 'start', padding: 10,
        }}>

          <Routes>
            <Route path="/" element={<div>Welcome, {user?.nickname}</div>}/>
            <Route path="/users" element={<UsersTab/>}/>
            <Route path="/providers" element={<ProvidersTab/>}/>
            <Route path="/reviews" element={<ReviewsTab/>}/>
            <Route path="/settings" element={<SettingsTab/>}/>
          </Routes>

          <Outlet/>
        </div>
      </div>
  );
};