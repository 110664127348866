import React, {FormEvent, useState} from "react";
import {GenerateInvitationRequest, GenerateInvitationResponse} from "../../../compiled";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";

export const UsersTab = () => {

  const {getIdTokenClaims} = useAuth0();

  // The RPC callback from generated an invite. Setting this as state
  // causes it to render a message
  const [generatingCodes, setGeneratingCodes] = useState(false);
  const [generatingCodesError, setGeneratedCodesError] = useState('');
  const [response, setResponse] = useState<GenerateInvitationResponse>();

  const getBookingHost = () => {
    if (window.location.host.startsWith("localhost")) {
      return "http://localhost:3001";
    } else if (window.location.host.endsWith("thymebook.com")) {
      return "https://thymebook.com";
    }
    
    return "https://kenko.space";
  }

  const generateInvites = function (event: FormEvent<HTMLFormElement>) {

    event.preventDefault();

    setGeneratingCodes(false);
    setGeneratedCodesError('');

    let request = GenerateInvitationRequest.encode(new GenerateInvitationRequest({
      num: 1
    })).finish();

    SendRpc(getIdTokenClaims, 'generate_user_invite', request).then(r => {
      let response = GenerateInvitationResponse.decode(r);
      console.log('invite response', response);
      setResponse(response);

    }).catch(e => {
      console.log(e);
      setGeneratedCodesError('Error generating invites. ' + e)
    }).finally(() => {
      setGeneratingCodes(false);
    });
  };

  return (
      <>
        <div>
          <b>User admin</b>
          <br/>
          <br/>
        </div>

        <div>
          Generate an alpha invite code<br/><br/>
          <form onSubmit={generateInvites}>
            <button type="submit" value="Submit">Generate the code!!</button>
          </form>
          
          {generatingCodesError &&
              <div style={{color: 'red'}}>Error generating invite! {generatingCodesError}</div>}

          {response && response.inviteCodes &&
              <div>
                <br/>
                Please share this URL to invite a new user to the Kenko alpha
                <br/>

                {response.inviteCodes.map(code => {
                  return <a href={`${getBookingHost()}/redeemInvite/${code}`}>
                    {getBookingHost()}/redeemInvite/{code}
                  </a>
                })
                }
              </div>
          }
        </div>
      </>
  );
}
