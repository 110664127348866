/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.AdminInvitationProto = (function() {

    /**
     * Properties of an AdminInvitationProto.
     * @exports IAdminInvitationProto
     * @interface IAdminInvitationProto
     * @property {string|null} [inviteCode] AdminInvitationProto inviteCode
     * @property {number|Long|null} [generationTime] AdminInvitationProto generationTime
     * @property {boolean|null} [redeemed] AdminInvitationProto redeemed
     */

    /**
     * Constructs a new AdminInvitationProto.
     * @exports AdminInvitationProto
     * @classdesc Represents an AdminInvitationProto.
     * @implements IAdminInvitationProto
     * @constructor
     * @param {IAdminInvitationProto=} [properties] Properties to set
     */
    function AdminInvitationProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminInvitationProto inviteCode.
     * @member {string} inviteCode
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.inviteCode = "";

    /**
     * AdminInvitationProto generationTime.
     * @member {number|Long} generationTime
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.generationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminInvitationProto redeemed.
     * @member {boolean} redeemed
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.redeemed = false;

    /**
     * Creates a new AdminInvitationProto instance using the specified properties.
     * @function create
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto=} [properties] Properties to set
     * @returns {AdminInvitationProto} AdminInvitationProto instance
     */
    AdminInvitationProto.create = function create(properties) {
        return new AdminInvitationProto(properties);
    };

    /**
     * Encodes the specified AdminInvitationProto message. Does not implicitly {@link AdminInvitationProto.verify|verify} messages.
     * @function encode
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto} message AdminInvitationProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminInvitationProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.generationTime != null && Object.hasOwnProperty.call(message, "generationTime"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generationTime);
        if (message.redeemed != null && Object.hasOwnProperty.call(message, "redeemed"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.redeemed);
        return writer;
    };

    /**
     * Encodes the specified AdminInvitationProto message, length delimited. Does not implicitly {@link AdminInvitationProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto} message AdminInvitationProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminInvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminInvitationProto message from the specified reader or buffer.
     * @function decode
     * @memberof AdminInvitationProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminInvitationProto} AdminInvitationProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminInvitationProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminInvitationProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.generationTime = reader.int64();
                    break;
                }
            case 3: {
                    message.redeemed = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminInvitationProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminInvitationProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminInvitationProto} AdminInvitationProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminInvitationProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminInvitationProto message.
     * @function verify
     * @memberof AdminInvitationProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminInvitationProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.generationTime != null && message.hasOwnProperty("generationTime"))
            if (!$util.isInteger(message.generationTime) && !(message.generationTime && $util.isInteger(message.generationTime.low) && $util.isInteger(message.generationTime.high)))
                return "generationTime: integer|Long expected";
        if (message.redeemed != null && message.hasOwnProperty("redeemed"))
            if (typeof message.redeemed !== "boolean")
                return "redeemed: boolean expected";
        return null;
    };

    /**
     * Creates an AdminInvitationProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminInvitationProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminInvitationProto} AdminInvitationProto
     */
    AdminInvitationProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminInvitationProto)
            return object;
        var message = new $root.AdminInvitationProto();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.generationTime != null)
            if ($util.Long)
                (message.generationTime = $util.Long.fromValue(object.generationTime)).unsigned = false;
            else if (typeof object.generationTime === "string")
                message.generationTime = parseInt(object.generationTime, 10);
            else if (typeof object.generationTime === "number")
                message.generationTime = object.generationTime;
            else if (typeof object.generationTime === "object")
                message.generationTime = new $util.LongBits(object.generationTime.low >>> 0, object.generationTime.high >>> 0).toNumber();
        if (object.redeemed != null)
            message.redeemed = Boolean(object.redeemed);
        return message;
    };

    /**
     * Creates a plain object from an AdminInvitationProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminInvitationProto
     * @static
     * @param {AdminInvitationProto} message AdminInvitationProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminInvitationProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.inviteCode = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.generationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.generationTime = options.longs === String ? "0" : 0;
            object.redeemed = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.generationTime != null && message.hasOwnProperty("generationTime"))
            if (typeof message.generationTime === "number")
                object.generationTime = options.longs === String ? String(message.generationTime) : message.generationTime;
            else
                object.generationTime = options.longs === String ? $util.Long.prototype.toString.call(message.generationTime) : options.longs === Number ? new $util.LongBits(message.generationTime.low >>> 0, message.generationTime.high >>> 0).toNumber() : message.generationTime;
        if (message.redeemed != null && message.hasOwnProperty("redeemed"))
            object.redeemed = message.redeemed;
        return object;
    };

    /**
     * Converts this AdminInvitationProto to JSON.
     * @function toJSON
     * @memberof AdminInvitationProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminInvitationProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminInvitationProto
     * @function getTypeUrl
     * @memberof AdminInvitationProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminInvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminInvitationProto";
    };

    return AdminInvitationProto;
})();

$root.CheckAdminRoleRequest = (function() {

    /**
     * Properties of a CheckAdminRoleRequest.
     * @exports ICheckAdminRoleRequest
     * @interface ICheckAdminRoleRequest
     */

    /**
     * Constructs a new CheckAdminRoleRequest.
     * @exports CheckAdminRoleRequest
     * @classdesc Represents a CheckAdminRoleRequest.
     * @implements ICheckAdminRoleRequest
     * @constructor
     * @param {ICheckAdminRoleRequest=} [properties] Properties to set
     */
    function CheckAdminRoleRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CheckAdminRoleRequest instance using the specified properties.
     * @function create
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest=} [properties] Properties to set
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest instance
     */
    CheckAdminRoleRequest.create = function create(properties) {
        return new CheckAdminRoleRequest(properties);
    };

    /**
     * Encodes the specified CheckAdminRoleRequest message. Does not implicitly {@link CheckAdminRoleRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest} message CheckAdminRoleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CheckAdminRoleRequest message, length delimited. Does not implicitly {@link CheckAdminRoleRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest} message CheckAdminRoleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckAdminRoleRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckAdminRoleRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckAdminRoleRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckAdminRoleRequest message.
     * @function verify
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckAdminRoleRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CheckAdminRoleRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     */
    CheckAdminRoleRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckAdminRoleRequest)
            return object;
        return new $root.CheckAdminRoleRequest();
    };

    /**
     * Creates a plain object from a CheckAdminRoleRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {CheckAdminRoleRequest} message CheckAdminRoleRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckAdminRoleRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CheckAdminRoleRequest to JSON.
     * @function toJSON
     * @memberof CheckAdminRoleRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckAdminRoleRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckAdminRoleRequest
     * @function getTypeUrl
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckAdminRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckAdminRoleRequest";
    };

    return CheckAdminRoleRequest;
})();

$root.CheckAdminRoleResponse = (function() {

    /**
     * Properties of a CheckAdminRoleResponse.
     * @exports ICheckAdminRoleResponse
     * @interface ICheckAdminRoleResponse
     * @property {boolean|null} [okay] CheckAdminRoleResponse okay
     * @property {boolean|null} [isAdmin] CheckAdminRoleResponse isAdmin
     */

    /**
     * Constructs a new CheckAdminRoleResponse.
     * @exports CheckAdminRoleResponse
     * @classdesc Represents a CheckAdminRoleResponse.
     * @implements ICheckAdminRoleResponse
     * @constructor
     * @param {ICheckAdminRoleResponse=} [properties] Properties to set
     */
    function CheckAdminRoleResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckAdminRoleResponse okay.
     * @member {boolean} okay
     * @memberof CheckAdminRoleResponse
     * @instance
     */
    CheckAdminRoleResponse.prototype.okay = false;

    /**
     * CheckAdminRoleResponse isAdmin.
     * @member {boolean} isAdmin
     * @memberof CheckAdminRoleResponse
     * @instance
     */
    CheckAdminRoleResponse.prototype.isAdmin = false;

    /**
     * Creates a new CheckAdminRoleResponse instance using the specified properties.
     * @function create
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse=} [properties] Properties to set
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse instance
     */
    CheckAdminRoleResponse.create = function create(properties) {
        return new CheckAdminRoleResponse(properties);
    };

    /**
     * Encodes the specified CheckAdminRoleResponse message. Does not implicitly {@link CheckAdminRoleResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse} message CheckAdminRoleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isAdmin);
        return writer;
    };

    /**
     * Encodes the specified CheckAdminRoleResponse message, length delimited. Does not implicitly {@link CheckAdminRoleResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse} message CheckAdminRoleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckAdminRoleResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckAdminRoleResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.isAdmin = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckAdminRoleResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckAdminRoleResponse message.
     * @function verify
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckAdminRoleResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
            if (typeof message.isAdmin !== "boolean")
                return "isAdmin: boolean expected";
        return null;
    };

    /**
     * Creates a CheckAdminRoleResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     */
    CheckAdminRoleResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckAdminRoleResponse)
            return object;
        var message = new $root.CheckAdminRoleResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.isAdmin != null)
            message.isAdmin = Boolean(object.isAdmin);
        return message;
    };

    /**
     * Creates a plain object from a CheckAdminRoleResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {CheckAdminRoleResponse} message CheckAdminRoleResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckAdminRoleResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.isAdmin = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
            object.isAdmin = message.isAdmin;
        return object;
    };

    /**
     * Converts this CheckAdminRoleResponse to JSON.
     * @function toJSON
     * @memberof CheckAdminRoleResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckAdminRoleResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckAdminRoleResponse
     * @function getTypeUrl
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckAdminRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckAdminRoleResponse";
    };

    return CheckAdminRoleResponse;
})();

$root.GenerateInvitationRequest = (function() {

    /**
     * Properties of a GenerateInvitationRequest.
     * @exports IGenerateInvitationRequest
     * @interface IGenerateInvitationRequest
     * @property {number|null} [num] GenerateInvitationRequest num
     */

    /**
     * Constructs a new GenerateInvitationRequest.
     * @exports GenerateInvitationRequest
     * @classdesc Represents a GenerateInvitationRequest.
     * @implements IGenerateInvitationRequest
     * @constructor
     * @param {IGenerateInvitationRequest=} [properties] Properties to set
     */
    function GenerateInvitationRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateInvitationRequest num.
     * @member {number} num
     * @memberof GenerateInvitationRequest
     * @instance
     */
    GenerateInvitationRequest.prototype.num = 0;

    /**
     * Creates a new GenerateInvitationRequest instance using the specified properties.
     * @function create
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest=} [properties] Properties to set
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest instance
     */
    GenerateInvitationRequest.create = function create(properties) {
        return new GenerateInvitationRequest(properties);
    };

    /**
     * Encodes the specified GenerateInvitationRequest message. Does not implicitly {@link GenerateInvitationRequest.verify|verify} messages.
     * @function encode
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest} message GenerateInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.num != null && Object.hasOwnProperty.call(message, "num"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.num);
        return writer;
    };

    /**
     * Encodes the specified GenerateInvitationRequest message, length delimited. Does not implicitly {@link GenerateInvitationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest} message GenerateInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateInvitationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateInvitationRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.num = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateInvitationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateInvitationRequest message.
     * @function verify
     * @memberof GenerateInvitationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateInvitationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.num != null && message.hasOwnProperty("num"))
            if (!$util.isInteger(message.num))
                return "num: integer expected";
        return null;
    };

    /**
     * Creates a GenerateInvitationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateInvitationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     */
    GenerateInvitationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateInvitationRequest)
            return object;
        var message = new $root.GenerateInvitationRequest();
        if (object.num != null)
            message.num = object.num | 0;
        return message;
    };

    /**
     * Creates a plain object from a GenerateInvitationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateInvitationRequest
     * @static
     * @param {GenerateInvitationRequest} message GenerateInvitationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateInvitationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.num = 0;
        if (message.num != null && message.hasOwnProperty("num"))
            object.num = message.num;
        return object;
    };

    /**
     * Converts this GenerateInvitationRequest to JSON.
     * @function toJSON
     * @memberof GenerateInvitationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateInvitationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateInvitationRequest
     * @function getTypeUrl
     * @memberof GenerateInvitationRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateInvitationRequest";
    };

    return GenerateInvitationRequest;
})();

$root.GenerateInvitationResponse = (function() {

    /**
     * Properties of a GenerateInvitationResponse.
     * @exports IGenerateInvitationResponse
     * @interface IGenerateInvitationResponse
     * @property {boolean|null} [okay] GenerateInvitationResponse okay
     * @property {Array.<string>|null} [inviteCodes] GenerateInvitationResponse inviteCodes
     */

    /**
     * Constructs a new GenerateInvitationResponse.
     * @exports GenerateInvitationResponse
     * @classdesc Represents a GenerateInvitationResponse.
     * @implements IGenerateInvitationResponse
     * @constructor
     * @param {IGenerateInvitationResponse=} [properties] Properties to set
     */
    function GenerateInvitationResponse(properties) {
        this.inviteCodes = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateInvitationResponse okay.
     * @member {boolean} okay
     * @memberof GenerateInvitationResponse
     * @instance
     */
    GenerateInvitationResponse.prototype.okay = false;

    /**
     * GenerateInvitationResponse inviteCodes.
     * @member {Array.<string>} inviteCodes
     * @memberof GenerateInvitationResponse
     * @instance
     */
    GenerateInvitationResponse.prototype.inviteCodes = $util.emptyArray;

    /**
     * Creates a new GenerateInvitationResponse instance using the specified properties.
     * @function create
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse=} [properties] Properties to set
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse instance
     */
    GenerateInvitationResponse.create = function create(properties) {
        return new GenerateInvitationResponse(properties);
    };

    /**
     * Encodes the specified GenerateInvitationResponse message. Does not implicitly {@link GenerateInvitationResponse.verify|verify} messages.
     * @function encode
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse} message GenerateInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.inviteCodes != null && message.inviteCodes.length)
            for (var i = 0; i < message.inviteCodes.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCodes[i]);
        return writer;
    };

    /**
     * Encodes the specified GenerateInvitationResponse message, length delimited. Does not implicitly {@link GenerateInvitationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse} message GenerateInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateInvitationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateInvitationResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.inviteCodes && message.inviteCodes.length))
                        message.inviteCodes = [];
                    message.inviteCodes.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateInvitationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateInvitationResponse message.
     * @function verify
     * @memberof GenerateInvitationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateInvitationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.inviteCodes != null && message.hasOwnProperty("inviteCodes")) {
            if (!Array.isArray(message.inviteCodes))
                return "inviteCodes: array expected";
            for (var i = 0; i < message.inviteCodes.length; ++i)
                if (!$util.isString(message.inviteCodes[i]))
                    return "inviteCodes: string[] expected";
        }
        return null;
    };

    /**
     * Creates a GenerateInvitationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateInvitationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     */
    GenerateInvitationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateInvitationResponse)
            return object;
        var message = new $root.GenerateInvitationResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.inviteCodes) {
            if (!Array.isArray(object.inviteCodes))
                throw TypeError(".GenerateInvitationResponse.inviteCodes: array expected");
            message.inviteCodes = [];
            for (var i = 0; i < object.inviteCodes.length; ++i)
                message.inviteCodes[i] = String(object.inviteCodes[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a GenerateInvitationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateInvitationResponse
     * @static
     * @param {GenerateInvitationResponse} message GenerateInvitationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateInvitationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.inviteCodes = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.inviteCodes && message.inviteCodes.length) {
            object.inviteCodes = [];
            for (var j = 0; j < message.inviteCodes.length; ++j)
                object.inviteCodes[j] = message.inviteCodes[j];
        }
        return object;
    };

    /**
     * Converts this GenerateInvitationResponse to JSON.
     * @function toJSON
     * @memberof GenerateInvitationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateInvitationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateInvitationResponse
     * @function getTypeUrl
     * @memberof GenerateInvitationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateInvitationResponse";
    };

    return GenerateInvitationResponse;
})();

$root.GetExistingInvitationsRequest = (function() {

    /**
     * Properties of a GetExistingInvitationsRequest.
     * @exports IGetExistingInvitationsRequest
     * @interface IGetExistingInvitationsRequest
     * @property {string|null} [networkId] GetExistingInvitationsRequest networkId
     */

    /**
     * Constructs a new GetExistingInvitationsRequest.
     * @exports GetExistingInvitationsRequest
     * @classdesc Represents a GetExistingInvitationsRequest.
     * @implements IGetExistingInvitationsRequest
     * @constructor
     * @param {IGetExistingInvitationsRequest=} [properties] Properties to set
     */
    function GetExistingInvitationsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetExistingInvitationsRequest networkId.
     * @member {string} networkId
     * @memberof GetExistingInvitationsRequest
     * @instance
     */
    GetExistingInvitationsRequest.prototype.networkId = "";

    /**
     * Creates a new GetExistingInvitationsRequest instance using the specified properties.
     * @function create
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest=} [properties] Properties to set
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest instance
     */
    GetExistingInvitationsRequest.create = function create(properties) {
        return new GetExistingInvitationsRequest(properties);
    };

    /**
     * Encodes the specified GetExistingInvitationsRequest message. Does not implicitly {@link GetExistingInvitationsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest} message GetExistingInvitationsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        return writer;
    };

    /**
     * Encodes the specified GetExistingInvitationsRequest message, length delimited. Does not implicitly {@link GetExistingInvitationsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest} message GetExistingInvitationsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetExistingInvitationsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetExistingInvitationsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetExistingInvitationsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetExistingInvitationsRequest message.
     * @function verify
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetExistingInvitationsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        return null;
    };

    /**
     * Creates a GetExistingInvitationsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     */
    GetExistingInvitationsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetExistingInvitationsRequest)
            return object;
        var message = new $root.GetExistingInvitationsRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        return message;
    };

    /**
     * Creates a plain object from a GetExistingInvitationsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {GetExistingInvitationsRequest} message GetExistingInvitationsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetExistingInvitationsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.networkId = "";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        return object;
    };

    /**
     * Converts this GetExistingInvitationsRequest to JSON.
     * @function toJSON
     * @memberof GetExistingInvitationsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetExistingInvitationsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetExistingInvitationsRequest
     * @function getTypeUrl
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetExistingInvitationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetExistingInvitationsRequest";
    };

    return GetExistingInvitationsRequest;
})();

$root.GetExistingInvitationsResponse = (function() {

    /**
     * Properties of a GetExistingInvitationsResponse.
     * @exports IGetExistingInvitationsResponse
     * @interface IGetExistingInvitationsResponse
     * @property {boolean|null} [okay] GetExistingInvitationsResponse okay
     * @property {IAdminInvitationProto|null} [invitation] GetExistingInvitationsResponse invitation
     */

    /**
     * Constructs a new GetExistingInvitationsResponse.
     * @exports GetExistingInvitationsResponse
     * @classdesc Represents a GetExistingInvitationsResponse.
     * @implements IGetExistingInvitationsResponse
     * @constructor
     * @param {IGetExistingInvitationsResponse=} [properties] Properties to set
     */
    function GetExistingInvitationsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetExistingInvitationsResponse okay.
     * @member {boolean} okay
     * @memberof GetExistingInvitationsResponse
     * @instance
     */
    GetExistingInvitationsResponse.prototype.okay = false;

    /**
     * GetExistingInvitationsResponse invitation.
     * @member {IAdminInvitationProto|null|undefined} invitation
     * @memberof GetExistingInvitationsResponse
     * @instance
     */
    GetExistingInvitationsResponse.prototype.invitation = null;

    /**
     * Creates a new GetExistingInvitationsResponse instance using the specified properties.
     * @function create
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse=} [properties] Properties to set
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse instance
     */
    GetExistingInvitationsResponse.create = function create(properties) {
        return new GetExistingInvitationsResponse(properties);
    };

    /**
     * Encodes the specified GetExistingInvitationsResponse message. Does not implicitly {@link GetExistingInvitationsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse} message GetExistingInvitationsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.invitation != null && Object.hasOwnProperty.call(message, "invitation"))
            $root.AdminInvitationProto.encode(message.invitation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetExistingInvitationsResponse message, length delimited. Does not implicitly {@link GetExistingInvitationsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse} message GetExistingInvitationsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetExistingInvitationsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetExistingInvitationsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.invitation = $root.AdminInvitationProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetExistingInvitationsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetExistingInvitationsResponse message.
     * @function verify
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetExistingInvitationsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.invitation != null && message.hasOwnProperty("invitation")) {
            var error = $root.AdminInvitationProto.verify(message.invitation);
            if (error)
                return "invitation." + error;
        }
        return null;
    };

    /**
     * Creates a GetExistingInvitationsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     */
    GetExistingInvitationsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetExistingInvitationsResponse)
            return object;
        var message = new $root.GetExistingInvitationsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.invitation != null) {
            if (typeof object.invitation !== "object")
                throw TypeError(".GetExistingInvitationsResponse.invitation: object expected");
            message.invitation = $root.AdminInvitationProto.fromObject(object.invitation);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetExistingInvitationsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {GetExistingInvitationsResponse} message GetExistingInvitationsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetExistingInvitationsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.invitation = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.invitation != null && message.hasOwnProperty("invitation"))
            object.invitation = $root.AdminInvitationProto.toObject(message.invitation, options);
        return object;
    };

    /**
     * Converts this GetExistingInvitationsResponse to JSON.
     * @function toJSON
     * @memberof GetExistingInvitationsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetExistingInvitationsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetExistingInvitationsResponse
     * @function getTypeUrl
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetExistingInvitationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetExistingInvitationsResponse";
    };

    return GetExistingInvitationsResponse;
})();

$root.UpdateSettingsRequest = (function() {

    /**
     * Properties of an UpdateSettingsRequest.
     * @exports IUpdateSettingsRequest
     * @interface IUpdateSettingsRequest
     * @property {space.kenko.proto.ISettingsProto|null} [settings] UpdateSettingsRequest settings
     */

    /**
     * Constructs a new UpdateSettingsRequest.
     * @exports UpdateSettingsRequest
     * @classdesc Represents an UpdateSettingsRequest.
     * @implements IUpdateSettingsRequest
     * @constructor
     * @param {IUpdateSettingsRequest=} [properties] Properties to set
     */
    function UpdateSettingsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSettingsRequest settings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} settings
     * @memberof UpdateSettingsRequest
     * @instance
     */
    UpdateSettingsRequest.prototype.settings = null;

    /**
     * Creates a new UpdateSettingsRequest instance using the specified properties.
     * @function create
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest=} [properties] Properties to set
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest instance
     */
    UpdateSettingsRequest.create = function create(properties) {
        return new UpdateSettingsRequest(properties);
    };

    /**
     * Encodes the specified UpdateSettingsRequest message. Does not implicitly {@link UpdateSettingsRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest} message UpdateSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
            $root.space.kenko.proto.SettingsProto.encode(message.settings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSettingsRequest message, length delimited. Does not implicitly {@link UpdateSettingsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest} message UpdateSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSettingsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSettingsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.settings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSettingsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSettingsRequest message.
     * @function verify
     * @memberof UpdateSettingsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSettingsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.settings != null && message.hasOwnProperty("settings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.settings);
            if (error)
                return "settings." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSettingsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSettingsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     */
    UpdateSettingsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSettingsRequest)
            return object;
        var message = new $root.UpdateSettingsRequest();
        if (object.settings != null) {
            if (typeof object.settings !== "object")
                throw TypeError(".UpdateSettingsRequest.settings: object expected");
            message.settings = $root.space.kenko.proto.SettingsProto.fromObject(object.settings);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSettingsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSettingsRequest
     * @static
     * @param {UpdateSettingsRequest} message UpdateSettingsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSettingsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.settings = null;
        if (message.settings != null && message.hasOwnProperty("settings"))
            object.settings = $root.space.kenko.proto.SettingsProto.toObject(message.settings, options);
        return object;
    };

    /**
     * Converts this UpdateSettingsRequest to JSON.
     * @function toJSON
     * @memberof UpdateSettingsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSettingsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSettingsRequest
     * @function getTypeUrl
     * @memberof UpdateSettingsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSettingsRequest";
    };

    return UpdateSettingsRequest;
})();

$root.UpdateSettingsResponse = (function() {

    /**
     * Properties of an UpdateSettingsResponse.
     * @exports IUpdateSettingsResponse
     * @interface IUpdateSettingsResponse
     * @property {boolean|null} [okay] UpdateSettingsResponse okay
     * @property {space.kenko.proto.ISettingsProto|null} [newSettings] UpdateSettingsResponse newSettings
     */

    /**
     * Constructs a new UpdateSettingsResponse.
     * @exports UpdateSettingsResponse
     * @classdesc Represents an UpdateSettingsResponse.
     * @implements IUpdateSettingsResponse
     * @constructor
     * @param {IUpdateSettingsResponse=} [properties] Properties to set
     */
    function UpdateSettingsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSettingsResponse okay.
     * @member {boolean} okay
     * @memberof UpdateSettingsResponse
     * @instance
     */
    UpdateSettingsResponse.prototype.okay = false;

    /**
     * UpdateSettingsResponse newSettings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} newSettings
     * @memberof UpdateSettingsResponse
     * @instance
     */
    UpdateSettingsResponse.prototype.newSettings = null;

    /**
     * Creates a new UpdateSettingsResponse instance using the specified properties.
     * @function create
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse=} [properties] Properties to set
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse instance
     */
    UpdateSettingsResponse.create = function create(properties) {
        return new UpdateSettingsResponse(properties);
    };

    /**
     * Encodes the specified UpdateSettingsResponse message. Does not implicitly {@link UpdateSettingsResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse} message UpdateSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.newSettings != null && Object.hasOwnProperty.call(message, "newSettings"))
            $root.space.kenko.proto.SettingsProto.encode(message.newSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSettingsResponse message, length delimited. Does not implicitly {@link UpdateSettingsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse} message UpdateSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSettingsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSettingsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.newSettings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSettingsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSettingsResponse message.
     * @function verify
     * @memberof UpdateSettingsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSettingsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.newSettings != null && message.hasOwnProperty("newSettings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.newSettings);
            if (error)
                return "newSettings." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSettingsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSettingsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     */
    UpdateSettingsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSettingsResponse)
            return object;
        var message = new $root.UpdateSettingsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.newSettings != null) {
            if (typeof object.newSettings !== "object")
                throw TypeError(".UpdateSettingsResponse.newSettings: object expected");
            message.newSettings = $root.space.kenko.proto.SettingsProto.fromObject(object.newSettings);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSettingsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSettingsResponse
     * @static
     * @param {UpdateSettingsResponse} message UpdateSettingsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSettingsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.newSettings = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.newSettings != null && message.hasOwnProperty("newSettings"))
            object.newSettings = $root.space.kenko.proto.SettingsProto.toObject(message.newSettings, options);
        return object;
    };

    /**
     * Converts this UpdateSettingsResponse to JSON.
     * @function toJSON
     * @memberof UpdateSettingsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSettingsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSettingsResponse
     * @function getTypeUrl
     * @memberof UpdateSettingsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSettingsResponse";
    };

    return UpdateSettingsResponse;
})();

$root.GetSettingsRequest = (function() {

    /**
     * Properties of a GetSettingsRequest.
     * @exports IGetSettingsRequest
     * @interface IGetSettingsRequest
     */

    /**
     * Constructs a new GetSettingsRequest.
     * @exports GetSettingsRequest
     * @classdesc Represents a GetSettingsRequest.
     * @implements IGetSettingsRequest
     * @constructor
     * @param {IGetSettingsRequest=} [properties] Properties to set
     */
    function GetSettingsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetSettingsRequest instance using the specified properties.
     * @function create
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest=} [properties] Properties to set
     * @returns {GetSettingsRequest} GetSettingsRequest instance
     */
    GetSettingsRequest.create = function create(properties) {
        return new GetSettingsRequest(properties);
    };

    /**
     * Encodes the specified GetSettingsRequest message. Does not implicitly {@link GetSettingsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest} message GetSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetSettingsRequest message, length delimited. Does not implicitly {@link GetSettingsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest} message GetSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSettingsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSettingsRequest} GetSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSettingsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSettingsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSettingsRequest} GetSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSettingsRequest message.
     * @function verify
     * @memberof GetSettingsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSettingsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetSettingsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSettingsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSettingsRequest} GetSettingsRequest
     */
    GetSettingsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSettingsRequest)
            return object;
        return new $root.GetSettingsRequest();
    };

    /**
     * Creates a plain object from a GetSettingsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSettingsRequest
     * @static
     * @param {GetSettingsRequest} message GetSettingsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSettingsRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetSettingsRequest to JSON.
     * @function toJSON
     * @memberof GetSettingsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSettingsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSettingsRequest
     * @function getTypeUrl
     * @memberof GetSettingsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSettingsRequest";
    };

    return GetSettingsRequest;
})();

$root.GetSettingsResponse = (function() {

    /**
     * Properties of a GetSettingsResponse.
     * @exports IGetSettingsResponse
     * @interface IGetSettingsResponse
     * @property {boolean|null} [okay] GetSettingsResponse okay
     * @property {space.kenko.proto.ISettingsProto|null} [newSettings] GetSettingsResponse newSettings
     */

    /**
     * Constructs a new GetSettingsResponse.
     * @exports GetSettingsResponse
     * @classdesc Represents a GetSettingsResponse.
     * @implements IGetSettingsResponse
     * @constructor
     * @param {IGetSettingsResponse=} [properties] Properties to set
     */
    function GetSettingsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetSettingsResponse okay.
     * @member {boolean} okay
     * @memberof GetSettingsResponse
     * @instance
     */
    GetSettingsResponse.prototype.okay = false;

    /**
     * GetSettingsResponse newSettings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} newSettings
     * @memberof GetSettingsResponse
     * @instance
     */
    GetSettingsResponse.prototype.newSettings = null;

    /**
     * Creates a new GetSettingsResponse instance using the specified properties.
     * @function create
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse=} [properties] Properties to set
     * @returns {GetSettingsResponse} GetSettingsResponse instance
     */
    GetSettingsResponse.create = function create(properties) {
        return new GetSettingsResponse(properties);
    };

    /**
     * Encodes the specified GetSettingsResponse message. Does not implicitly {@link GetSettingsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse} message GetSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.newSettings != null && Object.hasOwnProperty.call(message, "newSettings"))
            $root.space.kenko.proto.SettingsProto.encode(message.newSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetSettingsResponse message, length delimited. Does not implicitly {@link GetSettingsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse} message GetSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSettingsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSettingsResponse} GetSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSettingsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.newSettings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSettingsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSettingsResponse} GetSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSettingsResponse message.
     * @function verify
     * @memberof GetSettingsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSettingsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.newSettings != null && message.hasOwnProperty("newSettings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.newSettings);
            if (error)
                return "newSettings." + error;
        }
        return null;
    };

    /**
     * Creates a GetSettingsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSettingsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSettingsResponse} GetSettingsResponse
     */
    GetSettingsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSettingsResponse)
            return object;
        var message = new $root.GetSettingsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.newSettings != null) {
            if (typeof object.newSettings !== "object")
                throw TypeError(".GetSettingsResponse.newSettings: object expected");
            message.newSettings = $root.space.kenko.proto.SettingsProto.fromObject(object.newSettings);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetSettingsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSettingsResponse
     * @static
     * @param {GetSettingsResponse} message GetSettingsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSettingsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.newSettings = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.newSettings != null && message.hasOwnProperty("newSettings"))
            object.newSettings = $root.space.kenko.proto.SettingsProto.toObject(message.newSettings, options);
        return object;
    };

    /**
     * Converts this GetSettingsResponse to JSON.
     * @function toJSON
     * @memberof GetSettingsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSettingsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSettingsResponse
     * @function getTypeUrl
     * @memberof GetSettingsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSettingsResponse";
    };

    return GetSettingsResponse;
})();

$root.AdminBusinessProfileProto = (function() {

    /**
     * Properties of an AdminBusinessProfileProto.
     * @exports IAdminBusinessProfileProto
     * @interface IAdminBusinessProfileProto
     * @property {string|null} [businessId] AdminBusinessProfileProto businessId
     * @property {string|null} [versionId] AdminBusinessProfileProto versionId
     * @property {number|Long|null} [lastModifiedTimestamp] AdminBusinessProfileProto lastModifiedTimestamp
     * @property {number|Long|null} [reviewTimestamp] AdminBusinessProfileProto reviewTimestamp
     * @property {space.kenko.proto.IProfileReviewProto|null} [review] AdminBusinessProfileProto review
     * @property {space.kenko.proto.IBusinessProto|null} [proto] AdminBusinessProfileProto proto
     * @property {Array.<space.kenko.proto.IBusinessLocationProto>|null} [locations] AdminBusinessProfileProto locations
     * @property {Array.<space.kenko.proto.IProviderBioProto>|null} [providers] AdminBusinessProfileProto providers
     * @property {space.kenko.proto.IPracticeDetailsProto|null} [practice] AdminBusinessProfileProto practice
     */

    /**
     * Constructs a new AdminBusinessProfileProto.
     * @exports AdminBusinessProfileProto
     * @classdesc Represents an AdminBusinessProfileProto.
     * @implements IAdminBusinessProfileProto
     * @constructor
     * @param {IAdminBusinessProfileProto=} [properties] Properties to set
     */
    function AdminBusinessProfileProto(properties) {
        this.locations = [];
        this.providers = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminBusinessProfileProto businessId.
     * @member {string} businessId
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.businessId = "";

    /**
     * AdminBusinessProfileProto versionId.
     * @member {string} versionId
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.versionId = "";

    /**
     * AdminBusinessProfileProto lastModifiedTimestamp.
     * @member {number|Long} lastModifiedTimestamp
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.lastModifiedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminBusinessProfileProto reviewTimestamp.
     * @member {number|Long} reviewTimestamp
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.reviewTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminBusinessProfileProto review.
     * @member {space.kenko.proto.IProfileReviewProto|null|undefined} review
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.review = null;

    /**
     * AdminBusinessProfileProto proto.
     * @member {space.kenko.proto.IBusinessProto|null|undefined} proto
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.proto = null;

    /**
     * AdminBusinessProfileProto locations.
     * @member {Array.<space.kenko.proto.IBusinessLocationProto>} locations
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.locations = $util.emptyArray;

    /**
     * AdminBusinessProfileProto providers.
     * @member {Array.<space.kenko.proto.IProviderBioProto>} providers
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.providers = $util.emptyArray;

    /**
     * AdminBusinessProfileProto practice.
     * @member {space.kenko.proto.IPracticeDetailsProto|null|undefined} practice
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.practice = null;

    /**
     * Creates a new AdminBusinessProfileProto instance using the specified properties.
     * @function create
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {IAdminBusinessProfileProto=} [properties] Properties to set
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto instance
     */
    AdminBusinessProfileProto.create = function create(properties) {
        return new AdminBusinessProfileProto(properties);
    };

    /**
     * Encodes the specified AdminBusinessProfileProto message. Does not implicitly {@link AdminBusinessProfileProto.verify|verify} messages.
     * @function encode
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {IAdminBusinessProfileProto} message AdminBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.versionId);
        if (message.lastModifiedTimestamp != null && Object.hasOwnProperty.call(message, "lastModifiedTimestamp"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.lastModifiedTimestamp);
        if (message.reviewTimestamp != null && Object.hasOwnProperty.call(message, "reviewTimestamp"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.reviewTimestamp);
        if (message.review != null && Object.hasOwnProperty.call(message, "review"))
            $root.space.kenko.proto.ProfileReviewProto.encode(message.review, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.proto != null && Object.hasOwnProperty.call(message, "proto"))
            $root.space.kenko.proto.BusinessProto.encode(message.proto, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.locations != null && message.locations.length)
            for (var i = 0; i < message.locations.length; ++i)
                $root.space.kenko.proto.BusinessLocationProto.encode(message.locations[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.providers != null && message.providers.length)
            for (var i = 0; i < message.providers.length; ++i)
                $root.space.kenko.proto.ProviderBioProto.encode(message.providers[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
            $root.space.kenko.proto.PracticeDetailsProto.encode(message.practice, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AdminBusinessProfileProto message, length delimited. Does not implicitly {@link AdminBusinessProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {IAdminBusinessProfileProto} message AdminBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminBusinessProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminBusinessProfileProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.versionId = reader.string();
                    break;
                }
            case 3: {
                    message.lastModifiedTimestamp = reader.int64();
                    break;
                }
            case 4: {
                    message.reviewTimestamp = reader.int64();
                    break;
                }
            case 5: {
                    message.review = $root.space.kenko.proto.ProfileReviewProto.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.proto = $root.space.kenko.proto.BusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    if (!(message.locations && message.locations.length))
                        message.locations = [];
                    message.locations.push($root.space.kenko.proto.BusinessLocationProto.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32()));
                    break;
                }
            case 100: {
                    message.practice = $root.space.kenko.proto.PracticeDetailsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminBusinessProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminBusinessProfileProto message.
     * @function verify
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminBusinessProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            if (!$util.isString(message.versionId))
                return "versionId: string expected";
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (!$util.isInteger(message.lastModifiedTimestamp) && !(message.lastModifiedTimestamp && $util.isInteger(message.lastModifiedTimestamp.low) && $util.isInteger(message.lastModifiedTimestamp.high)))
                return "lastModifiedTimestamp: integer|Long expected";
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (!$util.isInteger(message.reviewTimestamp) && !(message.reviewTimestamp && $util.isInteger(message.reviewTimestamp.low) && $util.isInteger(message.reviewTimestamp.high)))
                return "reviewTimestamp: integer|Long expected";
        if (message.review != null && message.hasOwnProperty("review")) {
            var error = $root.space.kenko.proto.ProfileReviewProto.verify(message.review);
            if (error)
                return "review." + error;
        }
        if (message.proto != null && message.hasOwnProperty("proto")) {
            var error = $root.space.kenko.proto.BusinessProto.verify(message.proto);
            if (error)
                return "proto." + error;
        }
        if (message.locations != null && message.hasOwnProperty("locations")) {
            if (!Array.isArray(message.locations))
                return "locations: array expected";
            for (var i = 0; i < message.locations.length; ++i) {
                var error = $root.space.kenko.proto.BusinessLocationProto.verify(message.locations[i]);
                if (error)
                    return "locations." + error;
            }
        }
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (var i = 0; i < message.providers.length; ++i) {
                var error = $root.space.kenko.proto.ProviderBioProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.practice != null && message.hasOwnProperty("practice")) {
            var error = $root.space.kenko.proto.PracticeDetailsProto.verify(message.practice);
            if (error)
                return "practice." + error;
        }
        return null;
    };

    /**
     * Creates an AdminBusinessProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto
     */
    AdminBusinessProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminBusinessProfileProto)
            return object;
        var message = new $root.AdminBusinessProfileProto();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.versionId != null)
            message.versionId = String(object.versionId);
        if (object.lastModifiedTimestamp != null)
            if ($util.Long)
                (message.lastModifiedTimestamp = $util.Long.fromValue(object.lastModifiedTimestamp)).unsigned = false;
            else if (typeof object.lastModifiedTimestamp === "string")
                message.lastModifiedTimestamp = parseInt(object.lastModifiedTimestamp, 10);
            else if (typeof object.lastModifiedTimestamp === "number")
                message.lastModifiedTimestamp = object.lastModifiedTimestamp;
            else if (typeof object.lastModifiedTimestamp === "object")
                message.lastModifiedTimestamp = new $util.LongBits(object.lastModifiedTimestamp.low >>> 0, object.lastModifiedTimestamp.high >>> 0).toNumber();
        if (object.reviewTimestamp != null)
            if ($util.Long)
                (message.reviewTimestamp = $util.Long.fromValue(object.reviewTimestamp)).unsigned = false;
            else if (typeof object.reviewTimestamp === "string")
                message.reviewTimestamp = parseInt(object.reviewTimestamp, 10);
            else if (typeof object.reviewTimestamp === "number")
                message.reviewTimestamp = object.reviewTimestamp;
            else if (typeof object.reviewTimestamp === "object")
                message.reviewTimestamp = new $util.LongBits(object.reviewTimestamp.low >>> 0, object.reviewTimestamp.high >>> 0).toNumber();
        if (object.review != null) {
            if (typeof object.review !== "object")
                throw TypeError(".AdminBusinessProfileProto.review: object expected");
            message.review = $root.space.kenko.proto.ProfileReviewProto.fromObject(object.review);
        }
        if (object.proto != null) {
            if (typeof object.proto !== "object")
                throw TypeError(".AdminBusinessProfileProto.proto: object expected");
            message.proto = $root.space.kenko.proto.BusinessProto.fromObject(object.proto);
        }
        if (object.locations) {
            if (!Array.isArray(object.locations))
                throw TypeError(".AdminBusinessProfileProto.locations: array expected");
            message.locations = [];
            for (var i = 0; i < object.locations.length; ++i) {
                if (typeof object.locations[i] !== "object")
                    throw TypeError(".AdminBusinessProfileProto.locations: object expected");
                message.locations[i] = $root.space.kenko.proto.BusinessLocationProto.fromObject(object.locations[i]);
            }
        }
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".AdminBusinessProfileProto.providers: array expected");
            message.providers = [];
            for (var i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".AdminBusinessProfileProto.providers: object expected");
                message.providers[i] = $root.space.kenko.proto.ProviderBioProto.fromObject(object.providers[i]);
            }
        }
        if (object.practice != null) {
            if (typeof object.practice !== "object")
                throw TypeError(".AdminBusinessProfileProto.practice: object expected");
            message.practice = $root.space.kenko.proto.PracticeDetailsProto.fromObject(object.practice);
        }
        return message;
    };

    /**
     * Creates a plain object from an AdminBusinessProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {AdminBusinessProfileProto} message AdminBusinessProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminBusinessProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.locations = [];
            object.providers = [];
        }
        if (options.defaults) {
            object.businessId = "";
            object.versionId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.lastModifiedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastModifiedTimestamp = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.reviewTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.reviewTimestamp = options.longs === String ? "0" : 0;
            object.review = null;
            object.proto = null;
            object.practice = null;
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            object.versionId = message.versionId;
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (typeof message.lastModifiedTimestamp === "number")
                object.lastModifiedTimestamp = options.longs === String ? String(message.lastModifiedTimestamp) : message.lastModifiedTimestamp;
            else
                object.lastModifiedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastModifiedTimestamp) : options.longs === Number ? new $util.LongBits(message.lastModifiedTimestamp.low >>> 0, message.lastModifiedTimestamp.high >>> 0).toNumber() : message.lastModifiedTimestamp;
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (typeof message.reviewTimestamp === "number")
                object.reviewTimestamp = options.longs === String ? String(message.reviewTimestamp) : message.reviewTimestamp;
            else
                object.reviewTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.reviewTimestamp) : options.longs === Number ? new $util.LongBits(message.reviewTimestamp.low >>> 0, message.reviewTimestamp.high >>> 0).toNumber() : message.reviewTimestamp;
        if (message.review != null && message.hasOwnProperty("review"))
            object.review = $root.space.kenko.proto.ProfileReviewProto.toObject(message.review, options);
        if (message.proto != null && message.hasOwnProperty("proto"))
            object.proto = $root.space.kenko.proto.BusinessProto.toObject(message.proto, options);
        if (message.locations && message.locations.length) {
            object.locations = [];
            for (var j = 0; j < message.locations.length; ++j)
                object.locations[j] = $root.space.kenko.proto.BusinessLocationProto.toObject(message.locations[j], options);
        }
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (var j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.space.kenko.proto.ProviderBioProto.toObject(message.providers[j], options);
        }
        if (message.practice != null && message.hasOwnProperty("practice"))
            object.practice = $root.space.kenko.proto.PracticeDetailsProto.toObject(message.practice, options);
        return object;
    };

    /**
     * Converts this AdminBusinessProfileProto to JSON.
     * @function toJSON
     * @memberof AdminBusinessProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminBusinessProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminBusinessProfileProto
     * @function getTypeUrl
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminBusinessProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminBusinessProfileProto";
    };

    return AdminBusinessProfileProto;
})();

$root.GetPendingReviewsRequest = (function() {

    /**
     * Properties of a GetPendingReviewsRequest.
     * @exports IGetPendingReviewsRequest
     * @interface IGetPendingReviewsRequest
     * @property {string|null} [startIndex] GetPendingReviewsRequest startIndex
     */

    /**
     * Constructs a new GetPendingReviewsRequest.
     * @exports GetPendingReviewsRequest
     * @classdesc Represents a GetPendingReviewsRequest.
     * @implements IGetPendingReviewsRequest
     * @constructor
     * @param {IGetPendingReviewsRequest=} [properties] Properties to set
     */
    function GetPendingReviewsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetPendingReviewsRequest startIndex.
     * @member {string} startIndex
     * @memberof GetPendingReviewsRequest
     * @instance
     */
    GetPendingReviewsRequest.prototype.startIndex = "";

    /**
     * Creates a new GetPendingReviewsRequest instance using the specified properties.
     * @function create
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {IGetPendingReviewsRequest=} [properties] Properties to set
     * @returns {GetPendingReviewsRequest} GetPendingReviewsRequest instance
     */
    GetPendingReviewsRequest.create = function create(properties) {
        return new GetPendingReviewsRequest(properties);
    };

    /**
     * Encodes the specified GetPendingReviewsRequest message. Does not implicitly {@link GetPendingReviewsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {IGetPendingReviewsRequest} message GetPendingReviewsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingReviewsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.startIndex != null && Object.hasOwnProperty.call(message, "startIndex"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.startIndex);
        return writer;
    };

    /**
     * Encodes the specified GetPendingReviewsRequest message, length delimited. Does not implicitly {@link GetPendingReviewsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {IGetPendingReviewsRequest} message GetPendingReviewsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingReviewsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetPendingReviewsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetPendingReviewsRequest} GetPendingReviewsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingReviewsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetPendingReviewsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.startIndex = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetPendingReviewsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetPendingReviewsRequest} GetPendingReviewsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingReviewsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetPendingReviewsRequest message.
     * @function verify
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetPendingReviewsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.startIndex != null && message.hasOwnProperty("startIndex"))
            if (!$util.isString(message.startIndex))
                return "startIndex: string expected";
        return null;
    };

    /**
     * Creates a GetPendingReviewsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetPendingReviewsRequest} GetPendingReviewsRequest
     */
    GetPendingReviewsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetPendingReviewsRequest)
            return object;
        var message = new $root.GetPendingReviewsRequest();
        if (object.startIndex != null)
            message.startIndex = String(object.startIndex);
        return message;
    };

    /**
     * Creates a plain object from a GetPendingReviewsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {GetPendingReviewsRequest} message GetPendingReviewsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetPendingReviewsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.startIndex = "";
        if (message.startIndex != null && message.hasOwnProperty("startIndex"))
            object.startIndex = message.startIndex;
        return object;
    };

    /**
     * Converts this GetPendingReviewsRequest to JSON.
     * @function toJSON
     * @memberof GetPendingReviewsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetPendingReviewsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetPendingReviewsRequest
     * @function getTypeUrl
     * @memberof GetPendingReviewsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetPendingReviewsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetPendingReviewsRequest";
    };

    return GetPendingReviewsRequest;
})();

$root.GetPendingReviewsResponse = (function() {

    /**
     * Properties of a GetPendingReviewsResponse.
     * @exports IGetPendingReviewsResponse
     * @interface IGetPendingReviewsResponse
     * @property {boolean|null} [okay] GetPendingReviewsResponse okay
     * @property {string|null} [error] GetPendingReviewsResponse error
     * @property {Array.<IAdminBusinessProfileProto>|null} [profiles] GetPendingReviewsResponse profiles
     */

    /**
     * Constructs a new GetPendingReviewsResponse.
     * @exports GetPendingReviewsResponse
     * @classdesc Represents a GetPendingReviewsResponse.
     * @implements IGetPendingReviewsResponse
     * @constructor
     * @param {IGetPendingReviewsResponse=} [properties] Properties to set
     */
    function GetPendingReviewsResponse(properties) {
        this.profiles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetPendingReviewsResponse okay.
     * @member {boolean} okay
     * @memberof GetPendingReviewsResponse
     * @instance
     */
    GetPendingReviewsResponse.prototype.okay = false;

    /**
     * GetPendingReviewsResponse error.
     * @member {string} error
     * @memberof GetPendingReviewsResponse
     * @instance
     */
    GetPendingReviewsResponse.prototype.error = "";

    /**
     * GetPendingReviewsResponse profiles.
     * @member {Array.<IAdminBusinessProfileProto>} profiles
     * @memberof GetPendingReviewsResponse
     * @instance
     */
    GetPendingReviewsResponse.prototype.profiles = $util.emptyArray;

    /**
     * Creates a new GetPendingReviewsResponse instance using the specified properties.
     * @function create
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {IGetPendingReviewsResponse=} [properties] Properties to set
     * @returns {GetPendingReviewsResponse} GetPendingReviewsResponse instance
     */
    GetPendingReviewsResponse.create = function create(properties) {
        return new GetPendingReviewsResponse(properties);
    };

    /**
     * Encodes the specified GetPendingReviewsResponse message. Does not implicitly {@link GetPendingReviewsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {IGetPendingReviewsResponse} message GetPendingReviewsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingReviewsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.profiles != null && message.profiles.length)
            for (var i = 0; i < message.profiles.length; ++i)
                $root.AdminBusinessProfileProto.encode(message.profiles[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetPendingReviewsResponse message, length delimited. Does not implicitly {@link GetPendingReviewsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {IGetPendingReviewsResponse} message GetPendingReviewsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingReviewsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetPendingReviewsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetPendingReviewsResponse} GetPendingReviewsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingReviewsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetPendingReviewsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.profiles && message.profiles.length))
                        message.profiles = [];
                    message.profiles.push($root.AdminBusinessProfileProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetPendingReviewsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetPendingReviewsResponse} GetPendingReviewsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingReviewsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetPendingReviewsResponse message.
     * @function verify
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetPendingReviewsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.profiles != null && message.hasOwnProperty("profiles")) {
            if (!Array.isArray(message.profiles))
                return "profiles: array expected";
            for (var i = 0; i < message.profiles.length; ++i) {
                var error = $root.AdminBusinessProfileProto.verify(message.profiles[i]);
                if (error)
                    return "profiles." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetPendingReviewsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetPendingReviewsResponse} GetPendingReviewsResponse
     */
    GetPendingReviewsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetPendingReviewsResponse)
            return object;
        var message = new $root.GetPendingReviewsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.profiles) {
            if (!Array.isArray(object.profiles))
                throw TypeError(".GetPendingReviewsResponse.profiles: array expected");
            message.profiles = [];
            for (var i = 0; i < object.profiles.length; ++i) {
                if (typeof object.profiles[i] !== "object")
                    throw TypeError(".GetPendingReviewsResponse.profiles: object expected");
                message.profiles[i] = $root.AdminBusinessProfileProto.fromObject(object.profiles[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetPendingReviewsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {GetPendingReviewsResponse} message GetPendingReviewsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetPendingReviewsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.profiles = [];
        if (options.defaults) {
            object.okay = false;
            object.error = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.profiles && message.profiles.length) {
            object.profiles = [];
            for (var j = 0; j < message.profiles.length; ++j)
                object.profiles[j] = $root.AdminBusinessProfileProto.toObject(message.profiles[j], options);
        }
        return object;
    };

    /**
     * Converts this GetPendingReviewsResponse to JSON.
     * @function toJSON
     * @memberof GetPendingReviewsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetPendingReviewsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetPendingReviewsResponse
     * @function getTypeUrl
     * @memberof GetPendingReviewsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetPendingReviewsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetPendingReviewsResponse";
    };

    return GetPendingReviewsResponse;
})();

$root.FinishReviewRequest = (function() {

    /**
     * Properties of a FinishReviewRequest.
     * @exports IFinishReviewRequest
     * @interface IFinishReviewRequest
     * @property {string|null} [businessId] FinishReviewRequest businessId
     * @property {string|null} [version] FinishReviewRequest version
     * @property {space.kenko.proto.ApprovalStatus|null} [status] FinishReviewRequest status
     * @property {string|null} [reviewMessage] FinishReviewRequest reviewMessage
     */

    /**
     * Constructs a new FinishReviewRequest.
     * @exports FinishReviewRequest
     * @classdesc Represents a FinishReviewRequest.
     * @implements IFinishReviewRequest
     * @constructor
     * @param {IFinishReviewRequest=} [properties] Properties to set
     */
    function FinishReviewRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FinishReviewRequest businessId.
     * @member {string} businessId
     * @memberof FinishReviewRequest
     * @instance
     */
    FinishReviewRequest.prototype.businessId = "";

    /**
     * FinishReviewRequest version.
     * @member {string} version
     * @memberof FinishReviewRequest
     * @instance
     */
    FinishReviewRequest.prototype.version = "";

    /**
     * FinishReviewRequest status.
     * @member {space.kenko.proto.ApprovalStatus} status
     * @memberof FinishReviewRequest
     * @instance
     */
    FinishReviewRequest.prototype.status = 0;

    /**
     * FinishReviewRequest reviewMessage.
     * @member {string} reviewMessage
     * @memberof FinishReviewRequest
     * @instance
     */
    FinishReviewRequest.prototype.reviewMessage = "";

    /**
     * Creates a new FinishReviewRequest instance using the specified properties.
     * @function create
     * @memberof FinishReviewRequest
     * @static
     * @param {IFinishReviewRequest=} [properties] Properties to set
     * @returns {FinishReviewRequest} FinishReviewRequest instance
     */
    FinishReviewRequest.create = function create(properties) {
        return new FinishReviewRequest(properties);
    };

    /**
     * Encodes the specified FinishReviewRequest message. Does not implicitly {@link FinishReviewRequest.verify|verify} messages.
     * @function encode
     * @memberof FinishReviewRequest
     * @static
     * @param {IFinishReviewRequest} message FinishReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishReviewRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
        if (message.reviewMessage != null && Object.hasOwnProperty.call(message, "reviewMessage"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.reviewMessage);
        return writer;
    };

    /**
     * Encodes the specified FinishReviewRequest message, length delimited. Does not implicitly {@link FinishReviewRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FinishReviewRequest
     * @static
     * @param {IFinishReviewRequest} message FinishReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FinishReviewRequest message from the specified reader or buffer.
     * @function decode
     * @memberof FinishReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FinishReviewRequest} FinishReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishReviewRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FinishReviewRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.version = reader.string();
                    break;
                }
            case 3: {
                    message.status = reader.int32();
                    break;
                }
            case 4: {
                    message.reviewMessage = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FinishReviewRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FinishReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FinishReviewRequest} FinishReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishReviewRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FinishReviewRequest message.
     * @function verify
     * @memberof FinishReviewRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FinishReviewRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isString(message.version))
                return "version: string expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
            if (!$util.isString(message.reviewMessage))
                return "reviewMessage: string expected";
        return null;
    };

    /**
     * Creates a FinishReviewRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FinishReviewRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FinishReviewRequest} FinishReviewRequest
     */
    FinishReviewRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.FinishReviewRequest)
            return object;
        var message = new $root.FinishReviewRequest();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.version != null)
            message.version = String(object.version);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.status = 0;
            break;
        case "IN_REVIEW":
        case 1:
            message.status = 1;
            break;
        case "APPROVED":
        case 2:
            message.status = 2;
            break;
        case "REJECTED":
        case 3:
            message.status = 3;
            break;
        }
        if (object.reviewMessage != null)
            message.reviewMessage = String(object.reviewMessage);
        return message;
    };

    /**
     * Creates a plain object from a FinishReviewRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FinishReviewRequest
     * @static
     * @param {FinishReviewRequest} message FinishReviewRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FinishReviewRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.businessId = "";
            object.version = "";
            object.status = options.enums === String ? "UNKNOWN" : 0;
            object.reviewMessage = "";
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.space.kenko.proto.ApprovalStatus[message.status] === undefined ? message.status : $root.space.kenko.proto.ApprovalStatus[message.status] : message.status;
        if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
            object.reviewMessage = message.reviewMessage;
        return object;
    };

    /**
     * Converts this FinishReviewRequest to JSON.
     * @function toJSON
     * @memberof FinishReviewRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FinishReviewRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FinishReviewRequest
     * @function getTypeUrl
     * @memberof FinishReviewRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FinishReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FinishReviewRequest";
    };

    return FinishReviewRequest;
})();

$root.FinishReviewResponse = (function() {

    /**
     * Properties of a FinishReviewResponse.
     * @exports IFinishReviewResponse
     * @interface IFinishReviewResponse
     * @property {boolean|null} [okay] FinishReviewResponse okay
     * @property {IAdminBusinessProfileProto|null} [updated] FinishReviewResponse updated
     */

    /**
     * Constructs a new FinishReviewResponse.
     * @exports FinishReviewResponse
     * @classdesc Represents a FinishReviewResponse.
     * @implements IFinishReviewResponse
     * @constructor
     * @param {IFinishReviewResponse=} [properties] Properties to set
     */
    function FinishReviewResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FinishReviewResponse okay.
     * @member {boolean} okay
     * @memberof FinishReviewResponse
     * @instance
     */
    FinishReviewResponse.prototype.okay = false;

    /**
     * FinishReviewResponse updated.
     * @member {IAdminBusinessProfileProto|null|undefined} updated
     * @memberof FinishReviewResponse
     * @instance
     */
    FinishReviewResponse.prototype.updated = null;

    /**
     * Creates a new FinishReviewResponse instance using the specified properties.
     * @function create
     * @memberof FinishReviewResponse
     * @static
     * @param {IFinishReviewResponse=} [properties] Properties to set
     * @returns {FinishReviewResponse} FinishReviewResponse instance
     */
    FinishReviewResponse.create = function create(properties) {
        return new FinishReviewResponse(properties);
    };

    /**
     * Encodes the specified FinishReviewResponse message. Does not implicitly {@link FinishReviewResponse.verify|verify} messages.
     * @function encode
     * @memberof FinishReviewResponse
     * @static
     * @param {IFinishReviewResponse} message FinishReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishReviewResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
            $root.AdminBusinessProfileProto.encode(message.updated, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified FinishReviewResponse message, length delimited. Does not implicitly {@link FinishReviewResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FinishReviewResponse
     * @static
     * @param {IFinishReviewResponse} message FinishReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FinishReviewResponse message from the specified reader or buffer.
     * @function decode
     * @memberof FinishReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FinishReviewResponse} FinishReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishReviewResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FinishReviewResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.updated = $root.AdminBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FinishReviewResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FinishReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FinishReviewResponse} FinishReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishReviewResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FinishReviewResponse message.
     * @function verify
     * @memberof FinishReviewResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FinishReviewResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.updated != null && message.hasOwnProperty("updated")) {
            var error = $root.AdminBusinessProfileProto.verify(message.updated);
            if (error)
                return "updated." + error;
        }
        return null;
    };

    /**
     * Creates a FinishReviewResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FinishReviewResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FinishReviewResponse} FinishReviewResponse
     */
    FinishReviewResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FinishReviewResponse)
            return object;
        var message = new $root.FinishReviewResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.updated != null) {
            if (typeof object.updated !== "object")
                throw TypeError(".FinishReviewResponse.updated: object expected");
            message.updated = $root.AdminBusinessProfileProto.fromObject(object.updated);
        }
        return message;
    };

    /**
     * Creates a plain object from a FinishReviewResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FinishReviewResponse
     * @static
     * @param {FinishReviewResponse} message FinishReviewResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FinishReviewResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.updated = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.updated != null && message.hasOwnProperty("updated"))
            object.updated = $root.AdminBusinessProfileProto.toObject(message.updated, options);
        return object;
    };

    /**
     * Converts this FinishReviewResponse to JSON.
     * @function toJSON
     * @memberof FinishReviewResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FinishReviewResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FinishReviewResponse
     * @function getTypeUrl
     * @memberof FinishReviewResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FinishReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FinishReviewResponse";
    };

    return FinishReviewResponse;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        var kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            var proto = {};

            proto.LocalTimeProto = (function() {

                /**
                 * Properties of a LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalTimeProto
                 * @property {number|null} [hour] LocalTimeProto hour
                 * @property {number|null} [minute] LocalTimeProto minute
                 */

                /**
                 * Constructs a new LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalTimeProto.
                 * @implements ILocalTimeProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 */
                function LocalTimeProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalTimeProto hour.
                 * @member {number} hour
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.hour = 0;

                /**
                 * LocalTimeProto minute.
                 * @member {number} minute
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.minute = 0;

                /**
                 * Creates a new LocalTimeProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto instance
                 */
                LocalTimeProto.create = function create(properties) {
                    return new LocalTimeProto(properties);
                };

                /**
                 * Encodes the specified LocalTimeProto message. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minute);
                    return writer;
                };

                /**
                 * Encodes the specified LocalTimeProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalTimeProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hour = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minute = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalTimeProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalTimeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isInteger(message.hour))
                            return "hour: integer expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isInteger(message.minute))
                            return "minute: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalTimeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 */
                LocalTimeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalTimeProto)
                        return object;
                    var message = new $root.space.kenko.proto.LocalTimeProto();
                    if (object.hour != null)
                        message.hour = object.hour | 0;
                    if (object.minute != null)
                        message.minute = object.minute | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalTimeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.LocalTimeProto} message LocalTimeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalTimeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hour = 0;
                        object.minute = 0;
                    }
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    return object;
                };

                /**
                 * Converts this LocalTimeProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalTimeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalTimeProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalTimeProto";
                };

                return LocalTimeProto;
            })();

            proto.PriceProto = (function() {

                /**
                 * Properties of a PriceProto.
                 * @memberof space.kenko.proto
                 * @interface IPriceProto
                 * @property {number|null} [price] PriceProto price
                 * @property {string|null} [currencyCode] PriceProto currencyCode
                 */

                /**
                 * Constructs a new PriceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PriceProto.
                 * @implements IPriceProto
                 * @constructor
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 */
                function PriceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PriceProto price.
                 * @member {number} price
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.price = 0;

                /**
                 * PriceProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.currencyCode = "";

                /**
                 * Creates a new PriceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PriceProto} PriceProto instance
                 */
                PriceProto.create = function create(properties) {
                    return new PriceProto(properties);
                };

                /**
                 * Encodes the specified PriceProto message. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.price);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                    return writer;
                };

                /**
                 * Encodes the specified PriceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PriceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.price = reader.int32();
                                break;
                            }
                        case 2: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PriceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (!$util.isInteger(message.price))
                            return "price: integer expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    return null;
                };

                /**
                 * Creates a PriceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 */
                PriceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PriceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PriceProto();
                    if (object.price != null)
                        message.price = object.price | 0;
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    return message;
                };

                /**
                 * Creates a plain object from a PriceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.PriceProto} message PriceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.price = 0;
                        object.currencyCode = "";
                    }
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = message.price;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    return object;
                };

                /**
                 * Converts this PriceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PriceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PriceProto";
                };

                return PriceProto;
            })();

            proto.BusinessProto = (function() {

                /**
                 * Properties of a BusinessProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessProto
                 * @property {string|null} [businessName] BusinessProto businessName
                 * @property {string|null} [businessDescription] BusinessProto businessDescription
                 * @property {string|null} [websiteUrl] BusinessProto websiteUrl
                 * @property {string|null} [phoneNumber] BusinessProto phoneNumber
                 * @property {string|null} [email] BusinessProto email
                 * @property {Array.<string>|null} [imageUrl] BusinessProto imageUrl
                 * @property {string|null} [shortUrl] BusinessProto shortUrl
                 */

                /**
                 * Constructs a new BusinessProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessProto.
                 * @implements IBusinessProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 */
                function BusinessProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessProto businessName.
                 * @member {string} businessName
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessName = "";

                /**
                 * BusinessProto businessDescription.
                 * @member {string} businessDescription
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessDescription = "";

                /**
                 * BusinessProto websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.websiteUrl = "";

                /**
                 * BusinessProto phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.phoneNumber = "";

                /**
                 * BusinessProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.email = "";

                /**
                 * BusinessProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * BusinessProto shortUrl.
                 * @member {string} shortUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.shortUrl = "";

                /**
                 * Creates a new BusinessProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto instance
                 */
                BusinessProto.create = function create(properties) {
                    return new BusinessProto(properties);
                };

                /**
                 * Encodes the specified BusinessProto message. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.businessDescription != null && Object.hasOwnProperty.call(message, "businessDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessDescription);
                    if (message.websiteUrl != null && Object.hasOwnProperty.call(message, "websiteUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.websiteUrl);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phoneNumber);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageUrl[i]);
                    if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.shortUrl);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessDescription = reader.string();
                                break;
                            }
                        case 3: {
                                message.websiteUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.shortUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        if (!$util.isString(message.businessDescription))
                            return "businessDescription: string expected";
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        if (!$util.isString(message.shortUrl))
                            return "shortUrl: string expected";
                    return null;
                };

                /**
                 * Creates a BusinessProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 */
                BusinessProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessDescription != null)
                        message.businessDescription = String(object.businessDescription);
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    if (object.shortUrl != null)
                        message.shortUrl = String(object.shortUrl);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.BusinessProto} message BusinessProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.businessName = "";
                        object.businessDescription = "";
                        object.websiteUrl = "";
                        object.phoneNumber = "";
                        object.email = "";
                        object.shortUrl = "";
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        object.businessDescription = message.businessDescription;
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        object.shortUrl = message.shortUrl;
                    return object;
                };

                /**
                 * Converts this BusinessProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessProto";
                };

                return BusinessProto;
            })();

            /**
             * ApprovalStatus enum.
             * @name space.kenko.proto.ApprovalStatus
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} IN_REVIEW=1 IN_REVIEW value
             * @property {number} APPROVED=2 APPROVED value
             * @property {number} REJECTED=3 REJECTED value
             */
            proto.ApprovalStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "IN_REVIEW"] = 1;
                values[valuesById[2] = "APPROVED"] = 2;
                values[valuesById[3] = "REJECTED"] = 3;
                return values;
            })();

            proto.ProfileReviewProto = (function() {

                /**
                 * Properties of a ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @interface IProfileReviewProto
                 * @property {space.kenko.proto.ApprovalStatus|null} [status] ProfileReviewProto status
                 * @property {Array.<string>|null} [messages] ProfileReviewProto messages
                 */

                /**
                 * Constructs a new ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProfileReviewProto.
                 * @implements IProfileReviewProto
                 * @constructor
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 */
                function ProfileReviewProto(properties) {
                    this.messages = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProfileReviewProto status.
                 * @member {space.kenko.proto.ApprovalStatus} status
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.status = 0;

                /**
                 * ProfileReviewProto messages.
                 * @member {Array.<string>} messages
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.messages = $util.emptyArray;

                /**
                 * Creates a new ProfileReviewProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto instance
                 */
                ProfileReviewProto.create = function create(properties) {
                    return new ProfileReviewProto(properties);
                };

                /**
                 * Encodes the specified ProfileReviewProto message. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.messages != null && message.messages.length)
                        for (var i = 0; i < message.messages.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.messages[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ProfileReviewProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProfileReviewProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.messages && message.messages.length))
                                    message.messages = [];
                                message.messages.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProfileReviewProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProfileReviewProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.messages != null && message.hasOwnProperty("messages")) {
                        if (!Array.isArray(message.messages))
                            return "messages: array expected";
                        for (var i = 0; i < message.messages.length; ++i)
                            if (!$util.isString(message.messages[i]))
                                return "messages: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ProfileReviewProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 */
                ProfileReviewProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProfileReviewProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProfileReviewProto();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "IN_REVIEW":
                    case 1:
                        message.status = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.status = 2;
                        break;
                    case "REJECTED":
                    case 3:
                        message.status = 3;
                        break;
                    }
                    if (object.messages) {
                        if (!Array.isArray(object.messages))
                            throw TypeError(".space.kenko.proto.ProfileReviewProto.messages: array expected");
                        message.messages = [];
                        for (var i = 0; i < object.messages.length; ++i)
                            message.messages[i] = String(object.messages[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProfileReviewProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.ProfileReviewProto} message ProfileReviewProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProfileReviewProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.messages = [];
                    if (options.defaults)
                        object.status = options.enums === String ? "UNKNOWN" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.space.kenko.proto.ApprovalStatus[message.status] === undefined ? message.status : $root.space.kenko.proto.ApprovalStatus[message.status] : message.status;
                    if (message.messages && message.messages.length) {
                        object.messages = [];
                        for (var j = 0; j < message.messages.length; ++j)
                            object.messages[j] = message.messages[j];
                    }
                    return object;
                };

                /**
                 * Converts this ProfileReviewProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProfileReviewProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProfileReviewProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProfileReviewProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProfileReviewProto";
                };

                return ProfileReviewProto;
            })();

            proto.BusinessLocationProto = (function() {

                /**
                 * Properties of a BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessLocationProto
                 * @property {number|null} [locationId] BusinessLocationProto locationId
                 * @property {string|null} [locationName] BusinessLocationProto locationName
                 * @property {string|null} [address] BusinessLocationProto address
                 * @property {string|null} [phone] BusinessLocationProto phone
                 * @property {string|null} [email] BusinessLocationProto email
                 * @property {number|null} [lat] BusinessLocationProto lat
                 * @property {number|null} [lng] BusinessLocationProto lng
                 * @property {string|null} [timeZone] BusinessLocationProto timeZone
                 * @property {boolean|null} ["private"] BusinessLocationProto private
                 * @property {boolean|null} [disabled] BusinessLocationProto disabled
                 * @property {Array.<string>|null} [imageUrl] BusinessLocationProto imageUrl
                 */

                /**
                 * Constructs a new BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessLocationProto.
                 * @implements IBusinessLocationProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 */
                function BusinessLocationProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessLocationProto locationId.
                 * @member {number} locationId
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationId = 0;

                /**
                 * BusinessLocationProto locationName.
                 * @member {string} locationName
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationName = "";

                /**
                 * BusinessLocationProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.address = "";

                /**
                 * BusinessLocationProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.phone = "";

                /**
                 * BusinessLocationProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.email = "";

                /**
                 * BusinessLocationProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lat = 0;

                /**
                 * BusinessLocationProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lng = 0;

                /**
                 * BusinessLocationProto timeZone.
                 * @member {string} timeZone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.timeZone = "";

                /**
                 * BusinessLocationProto private.
                 * @member {boolean} private
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype["private"] = false;

                /**
                 * BusinessLocationProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.disabled = false;

                /**
                 * BusinessLocationProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * Creates a new BusinessLocationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto instance
                 */
                BusinessLocationProto.create = function create(properties) {
                    return new BusinessLocationProto(properties);
                };

                /**
                 * Encodes the specified BusinessLocationProto message. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.locationId);
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationName);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 7, wireType 1 =*/57).double(message.lng);
                    if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.timeZone);
                    if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message["private"]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.disabled);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.imageUrl[i]);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessLocationProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessLocationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.int32();
                                break;
                            }
                        case 2: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        case 4: {
                                message.phone = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                message.lat = reader.double();
                                break;
                            }
                        case 7: {
                                message.lng = reader.double();
                                break;
                            }
                        case 8: {
                                message.timeZone = reader.string();
                                break;
                            }
                        case 9: {
                                message["private"] = reader.bool();
                                break;
                            }
                        case 10: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 11: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessLocationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessLocationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isInteger(message.locationId))
                            return "locationId: integer expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        if (!$util.isString(message.timeZone))
                            return "timeZone: string expected";
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        if (typeof message["private"] !== "boolean")
                            return "private: boolean expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a BusinessLocationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 */
                BusinessLocationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessLocationProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessLocationProto();
                    if (object.locationId != null)
                        message.locationId = object.locationId | 0;
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    if (object.timeZone != null)
                        message.timeZone = String(object.timeZone);
                    if (object["private"] != null)
                        message["private"] = Boolean(object["private"]);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessLocationProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessLocationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.BusinessLocationProto} message BusinessLocationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessLocationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.locationId = 0;
                        object.locationName = "";
                        object.address = "";
                        object.phone = "";
                        object.email = "";
                        object.lat = 0;
                        object.lng = 0;
                        object.timeZone = "";
                        object["private"] = false;
                        object.disabled = false;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        object.timeZone = message.timeZone;
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        object["private"] = message["private"];
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    return object;
                };

                /**
                 * Converts this BusinessLocationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessLocationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessLocationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessLocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessLocationProto";
                };

                return BusinessLocationProto;
            })();

            proto.ProviderBioProto = (function() {

                /**
                 * Properties of a ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderBioProto
                 * @property {string|null} [providerId] ProviderBioProto providerId
                 * @property {string|null} [firstName] ProviderBioProto firstName
                 * @property {string|null} [lastName] ProviderBioProto lastName
                 * @property {string|null} [bio] ProviderBioProto bio
                 * @property {Array.<string>|null} [imageUrls] ProviderBioProto imageUrls
                 * @property {string|null} [email] ProviderBioProto email
                 * @property {string|null} [phone] ProviderBioProto phone
                 * @property {string|null} [jobTitle] ProviderBioProto jobTitle
                 * @property {boolean|null} [disabled] ProviderBioProto disabled
                 */

                /**
                 * Constructs a new ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderBioProto.
                 * @implements IProviderBioProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 */
                function ProviderBioProto(properties) {
                    this.imageUrls = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderBioProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.providerId = "";

                /**
                 * ProviderBioProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.firstName = "";

                /**
                 * ProviderBioProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.lastName = "";

                /**
                 * ProviderBioProto bio.
                 * @member {string} bio
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.bio = "";

                /**
                 * ProviderBioProto imageUrls.
                 * @member {Array.<string>} imageUrls
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.imageUrls = $util.emptyArray;

                /**
                 * ProviderBioProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.email = "";

                /**
                 * ProviderBioProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.phone = "";

                /**
                 * ProviderBioProto jobTitle.
                 * @member {string} jobTitle
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.jobTitle = "";

                /**
                 * ProviderBioProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.disabled = false;

                /**
                 * Creates a new ProviderBioProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto instance
                 */
                ProviderBioProto.create = function create(properties) {
                    return new ProviderBioProto(properties);
                };

                /**
                 * Encodes the specified ProviderBioProto message. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                    if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.bio);
                    if (message.imageUrls != null && message.imageUrls.length)
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.imageUrls[i]);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.email);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.phone);
                    if (message.jobTitle != null && Object.hasOwnProperty.call(message, "jobTitle"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jobTitle);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 100, wireType 0 =*/800).bool(message.disabled);
                    return writer;
                };

                /**
                 * Encodes the specified ProviderBioProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderBioProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 3: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 4: {
                                message.bio = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.imageUrls && message.imageUrls.length))
                                    message.imageUrls = [];
                                message.imageUrls.push(reader.string());
                                break;
                            }
                        case 8: {
                                message.email = reader.string();
                                break;
                            }
                        case 9: {
                                message.phone = reader.string();
                                break;
                            }
                        case 10: {
                                message.jobTitle = reader.string();
                                break;
                            }
                        case 100: {
                                message.disabled = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderBioProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderBioProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        if (!$util.isString(message.bio))
                            return "bio: string expected";
                    if (message.imageUrls != null && message.hasOwnProperty("imageUrls")) {
                        if (!Array.isArray(message.imageUrls))
                            return "imageUrls: array expected";
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            if (!$util.isString(message.imageUrls[i]))
                                return "imageUrls: string[] expected";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        if (!$util.isString(message.jobTitle))
                            return "jobTitle: string expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    return null;
                };

                /**
                 * Creates a ProviderBioProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 */
                ProviderBioProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderBioProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderBioProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.bio != null)
                        message.bio = String(object.bio);
                    if (object.imageUrls) {
                        if (!Array.isArray(object.imageUrls))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.imageUrls: array expected");
                        message.imageUrls = [];
                        for (var i = 0; i < object.imageUrls.length; ++i)
                            message.imageUrls[i] = String(object.imageUrls[i]);
                    }
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.jobTitle != null)
                        message.jobTitle = String(object.jobTitle);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderBioProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.ProviderBioProto} message ProviderBioProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderBioProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrls = [];
                    if (options.defaults) {
                        object.providerId = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.bio = "";
                        object.email = "";
                        object.phone = "";
                        object.jobTitle = "";
                        object.disabled = false;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        object.bio = message.bio;
                    if (message.imageUrls && message.imageUrls.length) {
                        object.imageUrls = [];
                        for (var j = 0; j < message.imageUrls.length; ++j)
                            object.imageUrls[j] = message.imageUrls[j];
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        object.jobTitle = message.jobTitle;
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    return object;
                };

                /**
                 * Converts this ProviderBioProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderBioProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderBioProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderBioProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderBioProto";
                };

                return ProviderBioProto;
            })();

            proto.PracticeDetailsProto = (function() {

                /**
                 * Properties of a PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IPracticeDetailsProto
                 * @property {Array.<space.kenko.proto.IServiceProto>|null} [services] PracticeDetailsProto services
                 * @property {Array.<space.kenko.proto.IPractitionerProto>|null} [practitioners] PracticeDetailsProto practitioners
                 */

                /**
                 * Constructs a new PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PracticeDetailsProto.
                 * @implements IPracticeDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 */
                function PracticeDetailsProto(properties) {
                    this.services = [];
                    this.practitioners = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PracticeDetailsProto services.
                 * @member {Array.<space.kenko.proto.IServiceProto>} services
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 */
                PracticeDetailsProto.prototype.services = $util.emptyArray;

                /**
                 * PracticeDetailsProto practitioners.
                 * @member {Array.<space.kenko.proto.IPractitionerProto>} practitioners
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 */
                PracticeDetailsProto.prototype.practitioners = $util.emptyArray;

                /**
                 * Creates a new PracticeDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto instance
                 */
                PracticeDetailsProto.create = function create(properties) {
                    return new PracticeDetailsProto(properties);
                };

                /**
                 * Encodes the specified PracticeDetailsProto message. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.services != null && message.services.length)
                        for (var i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.ServiceProto.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.practitioners != null && message.practitioners.length)
                        for (var i = 0; i < message.practitioners.length; ++i)
                            $root.space.kenko.proto.PractitionerProto.encode(message.practitioners[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PracticeDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PracticeDetailsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.ServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.practitioners && message.practitioners.length))
                                    message.practitioners = [];
                                message.practitioners.push($root.space.kenko.proto.PractitionerProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PracticeDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PracticeDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (var i = 0; i < message.services.length; ++i) {
                            var error = $root.space.kenko.proto.ServiceProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    if (message.practitioners != null && message.hasOwnProperty("practitioners")) {
                        if (!Array.isArray(message.practitioners))
                            return "practitioners: array expected";
                        for (var i = 0; i < message.practitioners.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerProto.verify(message.practitioners[i]);
                            if (error)
                                return "practitioners." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PracticeDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 */
                PracticeDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PracticeDetailsProto)
                        return object;
                    var message = new $root.space.kenko.proto.PracticeDetailsProto();
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: array expected");
                        message.services = [];
                        for (var i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.ServiceProto.fromObject(object.services[i]);
                        }
                    }
                    if (object.practitioners) {
                        if (!Array.isArray(object.practitioners))
                            throw TypeError(".space.kenko.proto.PracticeDetailsProto.practitioners: array expected");
                        message.practitioners = [];
                        for (var i = 0; i < object.practitioners.length; ++i) {
                            if (typeof object.practitioners[i] !== "object")
                                throw TypeError(".space.kenko.proto.PracticeDetailsProto.practitioners: object expected");
                            message.practitioners[i] = $root.space.kenko.proto.PractitionerProto.fromObject(object.practitioners[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PracticeDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.PracticeDetailsProto} message PracticeDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PracticeDetailsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.services = [];
                        object.practitioners = [];
                    }
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (var j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.ServiceProto.toObject(message.services[j], options);
                    }
                    if (message.practitioners && message.practitioners.length) {
                        object.practitioners = [];
                        for (var j = 0; j < message.practitioners.length; ++j)
                            object.practitioners[j] = $root.space.kenko.proto.PractitionerProto.toObject(message.practitioners[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PracticeDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PracticeDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PracticeDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PracticeDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PracticeDetailsProto";
                };

                return PracticeDetailsProto;
            })();

            proto.ServiceProto = (function() {

                /**
                 * Properties of a ServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceProto
                 * @property {string|null} [sku] ServiceProto sku
                 * @property {string|null} [name] ServiceProto name
                 * @property {string|null} [description] ServiceProto description
                 * @property {number|null} [lengthMinutes] ServiceProto lengthMinutes
                 * @property {string|null} [category] ServiceProto category
                 */

                /**
                 * Constructs a new ServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceProto.
                 * @implements IServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 */
                function ServiceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.sku = "";

                /**
                 * ServiceProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.name = "";

                /**
                 * ServiceProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.description = "";

                /**
                 * ServiceProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.category = "";

                /**
                 * Creates a new ServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto instance
                 */
                ServiceProto.create = function create(properties) {
                    return new ServiceProto(properties);
                };

                /**
                 * Encodes the specified ServiceProto message. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
                    return writer;
                };

                /**
                 * Encodes the specified ServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.category = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    return null;
                };

                /**
                 * Creates a ServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 */
                ServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.category != null)
                        message.category = String(object.category);
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.ServiceProto} message ServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.category = "";
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    return object;
                };

                /**
                 * Converts this ServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceProto";
                };

                return ServiceProto;
            })();

            proto.PractitionerProto = (function() {

                /**
                 * Properties of a PractitionerProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerProto
                 * @property {string|null} [providerId] PractitionerProto providerId
                 * @property {Array.<space.kenko.proto.IPractitionerServiceProto>|null} [services] PractitionerProto services
                 * @property {Array.<space.kenko.proto.IPractitionerAppointmentProto>|null} [appointments] PractitionerProto appointments
                 * @property {Array.<space.kenko.proto.IPractitionerAvailabilityBlockProto>|null} [defaultAvailabilityBlocks] PractitionerProto defaultAvailabilityBlocks
                 */

                /**
                 * Constructs a new PractitionerProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerProto.
                 * @implements IPractitionerProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerProto=} [properties] Properties to set
                 */
                function PractitionerProto(properties) {
                    this.services = [];
                    this.appointments = [];
                    this.defaultAvailabilityBlocks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 */
                PractitionerProto.prototype.providerId = "";

                /**
                 * PractitionerProto services.
                 * @member {Array.<space.kenko.proto.IPractitionerServiceProto>} services
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 */
                PractitionerProto.prototype.services = $util.emptyArray;

                /**
                 * PractitionerProto appointments.
                 * @member {Array.<space.kenko.proto.IPractitionerAppointmentProto>} appointments
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 */
                PractitionerProto.prototype.appointments = $util.emptyArray;

                /**
                 * PractitionerProto defaultAvailabilityBlocks.
                 * @member {Array.<space.kenko.proto.IPractitionerAvailabilityBlockProto>} defaultAvailabilityBlocks
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 */
                PractitionerProto.prototype.defaultAvailabilityBlocks = $util.emptyArray;

                /**
                 * Creates a new PractitionerProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto instance
                 */
                PractitionerProto.create = function create(properties) {
                    return new PractitionerProto(properties);
                };

                /**
                 * Encodes the specified PractitionerProto message. Does not implicitly {@link space.kenko.proto.PractitionerProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerProto} message PractitionerProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.services != null && message.services.length)
                        for (var i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.PractitionerServiceProto.encode(message.services[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.PractitionerAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.defaultAvailabilityBlocks != null && message.defaultAvailabilityBlocks.length)
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i)
                            $root.space.kenko.proto.PractitionerAvailabilityBlockProto.encode(message.defaultAvailabilityBlocks[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerProto} message PractitionerProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.PractitionerServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.PractitionerAppointmentProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length))
                                    message.defaultAvailabilityBlocks = [];
                                message.defaultAvailabilityBlocks.push($root.space.kenko.proto.PractitionerAvailabilityBlockProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (var i = 0; i < message.services.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerServiceProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerAppointmentProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.defaultAvailabilityBlocks != null && message.hasOwnProperty("defaultAvailabilityBlocks")) {
                        if (!Array.isArray(message.defaultAvailabilityBlocks))
                            return "defaultAvailabilityBlocks: array expected";
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerAvailabilityBlockProto.verify(message.defaultAvailabilityBlocks[i]);
                            if (error)
                                return "defaultAvailabilityBlocks." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto
                 */
                PractitionerProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.PractitionerProto.services: array expected");
                        message.services = [];
                        for (var i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.PractitionerProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.PractitionerServiceProto.fromObject(object.services[i]);
                        }
                    }
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.PractitionerProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.PractitionerProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.PractitionerAppointmentProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.defaultAvailabilityBlocks) {
                        if (!Array.isArray(object.defaultAvailabilityBlocks))
                            throw TypeError(".space.kenko.proto.PractitionerProto.defaultAvailabilityBlocks: array expected");
                        message.defaultAvailabilityBlocks = [];
                        for (var i = 0; i < object.defaultAvailabilityBlocks.length; ++i) {
                            if (typeof object.defaultAvailabilityBlocks[i] !== "object")
                                throw TypeError(".space.kenko.proto.PractitionerProto.defaultAvailabilityBlocks: object expected");
                            message.defaultAvailabilityBlocks[i] = $root.space.kenko.proto.PractitionerAvailabilityBlockProto.fromObject(object.defaultAvailabilityBlocks[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.PractitionerProto} message PractitionerProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.services = [];
                        object.appointments = [];
                        object.defaultAvailabilityBlocks = [];
                    }
                    if (options.defaults)
                        object.providerId = "";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (var j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.PractitionerServiceProto.toObject(message.services[j], options);
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.PractitionerAppointmentProto.toObject(message.appointments[j], options);
                    }
                    if (message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length) {
                        object.defaultAvailabilityBlocks = [];
                        for (var j = 0; j < message.defaultAvailabilityBlocks.length; ++j)
                            object.defaultAvailabilityBlocks[j] = $root.space.kenko.proto.PractitionerAvailabilityBlockProto.toObject(message.defaultAvailabilityBlocks[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PractitionerProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerProto";
                };

                return PractitionerProto;
            })();

            proto.PractitionerServiceProto = (function() {

                /**
                 * Properties of a PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerServiceProto
                 * @property {string|null} [sku] PractitionerServiceProto sku
                 * @property {string|null} [providerId] PractitionerServiceProto providerId
                 * @property {space.kenko.proto.IPriceProto|null} [price] PractitionerServiceProto price
                 */

                /**
                 * Constructs a new PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerServiceProto.
                 * @implements IPractitionerServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 */
                function PractitionerServiceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerServiceProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.sku = "";

                /**
                 * PractitionerServiceProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.providerId = "";

                /**
                 * PractitionerServiceProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.price = null;

                /**
                 * Creates a new PractitionerServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto instance
                 */
                PractitionerServiceProto.create = function create(properties) {
                    return new PractitionerServiceProto(properties);
                };

                /**
                 * Encodes the specified PractitionerServiceProto message. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 3: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 */
                PractitionerServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerServiceProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerServiceProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.PractitionerServiceProto} message PractitionerServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sku = "";
                        object.providerId = "";
                        object.price = null;
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    return object;
                };

                /**
                 * Converts this PractitionerServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerServiceProto";
                };

                return PractitionerServiceProto;
            })();

            proto.PractitionerAppointmentProto = (function() {

                /**
                 * Properties of a PractitionerAppointmentProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerAppointmentProto
                 * @property {string|null} [appointmentId] PractitionerAppointmentProto appointmentId
                 * @property {number|Long|null} [startTimeMs] PractitionerAppointmentProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] PractitionerAppointmentProto endTimeMs
                 * @property {string|null} [sku] PractitionerAppointmentProto sku
                 * @property {string|null} [clientName] PractitionerAppointmentProto clientName
                 */

                /**
                 * Constructs a new PractitionerAppointmentProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerAppointmentProto.
                 * @implements IPractitionerAppointmentProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerAppointmentProto=} [properties] Properties to set
                 */
                function PractitionerAppointmentProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerAppointmentProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.appointmentId = "";

                /**
                 * PractitionerAppointmentProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PractitionerAppointmentProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PractitionerAppointmentProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.sku = "";

                /**
                 * PractitionerAppointmentProto clientName.
                 * @member {string} clientName
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.clientName = "";

                /**
                 * Creates a new PractitionerAppointmentProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAppointmentProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto instance
                 */
                PractitionerAppointmentProto.create = function create(properties) {
                    return new PractitionerAppointmentProto(properties);
                };

                /**
                 * Encodes the specified PractitionerAppointmentProto message. Does not implicitly {@link space.kenko.proto.PractitionerAppointmentProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAppointmentProto} message PractitionerAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAppointmentProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.sku);
                    if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.clientName);
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerAppointmentProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerAppointmentProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAppointmentProto} message PractitionerAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAppointmentProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerAppointmentProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAppointmentProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerAppointmentProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.sku = reader.string();
                                break;
                            }
                        case 5: {
                                message.clientName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerAppointmentProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAppointmentProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerAppointmentProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerAppointmentProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        if (!$util.isString(message.clientName))
                            return "clientName: string expected";
                    return null;
                };

                /**
                 * Creates a PractitionerAppointmentProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto
                 */
                PractitionerAppointmentProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerAppointmentProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerAppointmentProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.clientName != null)
                        message.clientName = String(object.clientName);
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerAppointmentProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.PractitionerAppointmentProto} message PractitionerAppointmentProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerAppointmentProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.sku = "";
                        object.clientName = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        object.clientName = message.clientName;
                    return object;
                };

                /**
                 * Converts this PractitionerAppointmentProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerAppointmentProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerAppointmentProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerAppointmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerAppointmentProto";
                };

                return PractitionerAppointmentProto;
            })();

            proto.PractitionerAvailabilityBlockProto = (function() {

                /**
                 * Properties of a PractitionerAvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerAvailabilityBlockProto
                 * @property {string|null} [locationId] PractitionerAvailabilityBlockProto locationId
                 * @property {number|null} [dayOfWeek] PractitionerAvailabilityBlockProto dayOfWeek
                 * @property {space.kenko.proto.ILocalTimeProto|null} [start] PractitionerAvailabilityBlockProto start
                 * @property {space.kenko.proto.ILocalTimeProto|null} [end] PractitionerAvailabilityBlockProto end
                 */

                /**
                 * Constructs a new PractitionerAvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerAvailabilityBlockProto.
                 * @implements IPractitionerAvailabilityBlockProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto=} [properties] Properties to set
                 */
                function PractitionerAvailabilityBlockProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerAvailabilityBlockProto locationId.
                 * @member {string} locationId
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.locationId = "";

                /**
                 * PractitionerAvailabilityBlockProto dayOfWeek.
                 * @member {number} dayOfWeek
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.dayOfWeek = 0;

                /**
                 * PractitionerAvailabilityBlockProto start.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} start
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.start = null;

                /**
                 * PractitionerAvailabilityBlockProto end.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} end
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.end = null;

                /**
                 * Creates a new PractitionerAvailabilityBlockProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto instance
                 */
                PractitionerAvailabilityBlockProto.create = function create(properties) {
                    return new PractitionerAvailabilityBlockProto(properties);
                };

                /**
                 * Encodes the specified PractitionerAvailabilityBlockProto message. Does not implicitly {@link space.kenko.proto.PractitionerAvailabilityBlockProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto} message PractitionerAvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAvailabilityBlockProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dayOfWeek);
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.start, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.end, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerAvailabilityBlockProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerAvailabilityBlockProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto} message PractitionerAvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAvailabilityBlockProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerAvailabilityBlockProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAvailabilityBlockProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerAvailabilityBlockProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.dayOfWeek = reader.int32();
                                break;
                            }
                        case 3: {
                                message.start = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.end = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerAvailabilityBlockProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAvailabilityBlockProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerAvailabilityBlockProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerAvailabilityBlockProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        if (!$util.isInteger(message.dayOfWeek))
                            return "dayOfWeek: integer expected";
                    if (message.start != null && message.hasOwnProperty("start")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.start);
                        if (error)
                            return "start." + error;
                    }
                    if (message.end != null && message.hasOwnProperty("end")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.end);
                        if (error)
                            return "end." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerAvailabilityBlockProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto
                 */
                PractitionerAvailabilityBlockProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerAvailabilityBlockProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerAvailabilityBlockProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.dayOfWeek != null)
                        message.dayOfWeek = object.dayOfWeek | 0;
                    if (object.start != null) {
                        if (typeof object.start !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerAvailabilityBlockProto.start: object expected");
                        message.start = $root.space.kenko.proto.LocalTimeProto.fromObject(object.start);
                    }
                    if (object.end != null) {
                        if (typeof object.end !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerAvailabilityBlockProto.end: object expected");
                        message.end = $root.space.kenko.proto.LocalTimeProto.fromObject(object.end);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerAvailabilityBlockProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.PractitionerAvailabilityBlockProto} message PractitionerAvailabilityBlockProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerAvailabilityBlockProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.dayOfWeek = 0;
                        object.start = null;
                        object.end = null;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        object.dayOfWeek = message.dayOfWeek;
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = $root.space.kenko.proto.LocalTimeProto.toObject(message.start, options);
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = $root.space.kenko.proto.LocalTimeProto.toObject(message.end, options);
                    return object;
                };

                /**
                 * Converts this PractitionerAvailabilityBlockProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerAvailabilityBlockProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerAvailabilityBlockProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerAvailabilityBlockProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerAvailabilityBlockProto";
                };

                return PractitionerAvailabilityBlockProto;
            })();

            proto.ProviderLoginProto = (function() {

                /**
                 * Properties of a ProviderLoginProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderLoginProto
                 * @property {string|null} [firstName] ProviderLoginProto firstName
                 * @property {string|null} [lastName] ProviderLoginProto lastName
                 * @property {string|null} [bio] ProviderLoginProto bio
                 * @property {string|null} [email] ProviderLoginProto email
                 * @property {string|null} [phoneNumber] ProviderLoginProto phoneNumber
                 * @property {boolean|null} [agreedToTerms] ProviderLoginProto agreedToTerms
                 * @property {Array.<string>|null} [imageUrls] ProviderLoginProto imageUrls
                 * @property {string|null} [jobTitle] ProviderLoginProto jobTitle
                 */

                /**
                 * Constructs a new ProviderLoginProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderLoginProto.
                 * @implements IProviderLoginProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderLoginProto=} [properties] Properties to set
                 */
                function ProviderLoginProto(properties) {
                    this.imageUrls = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderLoginProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.firstName = "";

                /**
                 * ProviderLoginProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.lastName = "";

                /**
                 * ProviderLoginProto bio.
                 * @member {string} bio
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.bio = "";

                /**
                 * ProviderLoginProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.email = "";

                /**
                 * ProviderLoginProto phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.phoneNumber = "";

                /**
                 * ProviderLoginProto agreedToTerms.
                 * @member {boolean} agreedToTerms
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.agreedToTerms = false;

                /**
                 * ProviderLoginProto imageUrls.
                 * @member {Array.<string>} imageUrls
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.imageUrls = $util.emptyArray;

                /**
                 * ProviderLoginProto jobTitle.
                 * @member {string} jobTitle
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 */
                ProviderLoginProto.prototype.jobTitle = "";

                /**
                 * Creates a new ProviderLoginProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {space.kenko.proto.IProviderLoginProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderLoginProto} ProviderLoginProto instance
                 */
                ProviderLoginProto.create = function create(properties) {
                    return new ProviderLoginProto(properties);
                };

                /**
                 * Encodes the specified ProviderLoginProto message. Does not implicitly {@link space.kenko.proto.ProviderLoginProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {space.kenko.proto.IProviderLoginProto} message ProviderLoginProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderLoginProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.bio);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.phoneNumber);
                    if (message.agreedToTerms != null && Object.hasOwnProperty.call(message, "agreedToTerms"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.agreedToTerms);
                    if (message.imageUrls != null && message.imageUrls.length)
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.imageUrls[i]);
                    if (message.jobTitle != null && Object.hasOwnProperty.call(message, "jobTitle"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.jobTitle);
                    return writer;
                };

                /**
                 * Encodes the specified ProviderLoginProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderLoginProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {space.kenko.proto.IProviderLoginProto} message ProviderLoginProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderLoginProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderLoginProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderLoginProto} ProviderLoginProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderLoginProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderLoginProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 2: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 3: {
                                message.bio = reader.string();
                                break;
                            }
                        case 4: {
                                message.email = reader.string();
                                break;
                            }
                        case 5: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 6: {
                                message.agreedToTerms = reader.bool();
                                break;
                            }
                        case 7: {
                                if (!(message.imageUrls && message.imageUrls.length))
                                    message.imageUrls = [];
                                message.imageUrls.push(reader.string());
                                break;
                            }
                        case 8: {
                                message.jobTitle = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderLoginProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderLoginProto} ProviderLoginProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderLoginProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderLoginProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderLoginProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        if (!$util.isString(message.bio))
                            return "bio: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        if (typeof message.agreedToTerms !== "boolean")
                            return "agreedToTerms: boolean expected";
                    if (message.imageUrls != null && message.hasOwnProperty("imageUrls")) {
                        if (!Array.isArray(message.imageUrls))
                            return "imageUrls: array expected";
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            if (!$util.isString(message.imageUrls[i]))
                                return "imageUrls: string[] expected";
                    }
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        if (!$util.isString(message.jobTitle))
                            return "jobTitle: string expected";
                    return null;
                };

                /**
                 * Creates a ProviderLoginProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderLoginProto} ProviderLoginProto
                 */
                ProviderLoginProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderLoginProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderLoginProto();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.bio != null)
                        message.bio = String(object.bio);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.agreedToTerms != null)
                        message.agreedToTerms = Boolean(object.agreedToTerms);
                    if (object.imageUrls) {
                        if (!Array.isArray(object.imageUrls))
                            throw TypeError(".space.kenko.proto.ProviderLoginProto.imageUrls: array expected");
                        message.imageUrls = [];
                        for (var i = 0; i < object.imageUrls.length; ++i)
                            message.imageUrls[i] = String(object.imageUrls[i]);
                    }
                    if (object.jobTitle != null)
                        message.jobTitle = String(object.jobTitle);
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderLoginProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {space.kenko.proto.ProviderLoginProto} message ProviderLoginProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderLoginProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrls = [];
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.bio = "";
                        object.email = "";
                        object.phoneNumber = "";
                        object.agreedToTerms = false;
                        object.jobTitle = "";
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        object.bio = message.bio;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        object.agreedToTerms = message.agreedToTerms;
                    if (message.imageUrls && message.imageUrls.length) {
                        object.imageUrls = [];
                        for (var j = 0; j < message.imageUrls.length; ++j)
                            object.imageUrls[j] = message.imageUrls[j];
                    }
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        object.jobTitle = message.jobTitle;
                    return object;
                };

                /**
                 * Converts this ProviderLoginProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderLoginProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderLoginProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderLoginProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderLoginProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderLoginProto";
                };

                return ProviderLoginProto;
            })();

            proto.ServiceSchemaProto = (function() {

                /**
                 * Properties of a ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceSchemaProto
                 * @property {string|null} [sku] ServiceSchemaProto sku
                 * @property {string|null} [name] ServiceSchemaProto name
                 * @property {string|null} [description] ServiceSchemaProto description
                 * @property {number|null} [lengthMinutes] ServiceSchemaProto lengthMinutes
                 * @property {number|null} [price] ServiceSchemaProto price
                 * @property {string|null} [currencyCode] ServiceSchemaProto currencyCode
                 * @property {string|null} [category] ServiceSchemaProto category
                 */

                /**
                 * Constructs a new ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceSchemaProto.
                 * @implements IServiceSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 */
                function ServiceSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceSchemaProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.sku = "";

                /**
                 * ServiceSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.name = "";

                /**
                 * ServiceSchemaProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.description = "";

                /**
                 * ServiceSchemaProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceSchemaProto price.
                 * @member {number} price
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.price = 0;

                /**
                 * ServiceSchemaProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.currencyCode = "";

                /**
                 * ServiceSchemaProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.category = "";

                /**
                 * Creates a new ServiceSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto instance
                 */
                ServiceSchemaProto.create = function create(properties) {
                    return new ServiceSchemaProto(properties);
                };

                /**
                 * Encodes the specified ServiceSchemaProto message. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.price);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.currencyCode);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.category);
                    return writer;
                };

                /**
                 * Encodes the specified ServiceSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.price = reader.int32();
                                break;
                            }
                        case 6: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        case 7: {
                                message.category = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (!$util.isInteger(message.price))
                            return "price: integer expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    return null;
                };

                /**
                 * Creates a ServiceSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 */
                ServiceSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceSchemaProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.price != null)
                        message.price = object.price | 0;
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    if (object.category != null)
                        message.category = String(object.category);
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.ServiceSchemaProto} message ServiceSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.price = 0;
                        object.currencyCode = "";
                        object.category = "";
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = message.price;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    return object;
                };

                /**
                 * Converts this ServiceSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceSchemaProto";
                };

                return ServiceSchemaProto;
            })();

            proto.ProviderAppointmentProto = (function() {

                /**
                 * Properties of a ProviderAppointmentProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderAppointmentProto
                 * @property {string|null} [appointmentId] ProviderAppointmentProto appointmentId
                 * @property {number|Long|null} [startTimeMs] ProviderAppointmentProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] ProviderAppointmentProto endTimeMs
                 * @property {string|null} [sku] ProviderAppointmentProto sku
                 * @property {string|null} [clientName] ProviderAppointmentProto clientName
                 */

                /**
                 * Constructs a new ProviderAppointmentProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderAppointmentProto.
                 * @implements IProviderAppointmentProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderAppointmentProto=} [properties] Properties to set
                 */
                function ProviderAppointmentProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderAppointmentProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.appointmentId = "";

                /**
                 * ProviderAppointmentProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ProviderAppointmentProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ProviderAppointmentProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.sku = "";

                /**
                 * ProviderAppointmentProto clientName.
                 * @member {string} clientName
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.clientName = "";

                /**
                 * Creates a new ProviderAppointmentProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IProviderAppointmentProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto instance
                 */
                ProviderAppointmentProto.create = function create(properties) {
                    return new ProviderAppointmentProto(properties);
                };

                /**
                 * Encodes the specified ProviderAppointmentProto message. Does not implicitly {@link space.kenko.proto.ProviderAppointmentProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IProviderAppointmentProto} message ProviderAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderAppointmentProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.sku);
                    if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.clientName);
                    return writer;
                };

                /**
                 * Encodes the specified ProviderAppointmentProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderAppointmentProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IProviderAppointmentProto} message ProviderAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderAppointmentProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderAppointmentProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderAppointmentProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderAppointmentProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.sku = reader.string();
                                break;
                            }
                        case 5: {
                                message.clientName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderAppointmentProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderAppointmentProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderAppointmentProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderAppointmentProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        if (!$util.isString(message.clientName))
                            return "clientName: string expected";
                    return null;
                };

                /**
                 * Creates a ProviderAppointmentProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto
                 */
                ProviderAppointmentProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderAppointmentProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderAppointmentProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.clientName != null)
                        message.clientName = String(object.clientName);
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderAppointmentProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.ProviderAppointmentProto} message ProviderAppointmentProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderAppointmentProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.sku = "";
                        object.clientName = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        object.clientName = message.clientName;
                    return object;
                };

                /**
                 * Converts this ProviderAppointmentProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderAppointmentProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderAppointmentProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderAppointmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderAppointmentProto";
                };

                return ProviderAppointmentProto;
            })();

            proto.ProviderSchemaProto = (function() {

                /**
                 * Properties of a ProviderSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderSchemaProto
                 * @property {Array.<space.kenko.proto.IServiceSchemaProto>|null} [services] ProviderSchemaProto services
                 * @property {Array.<space.kenko.proto.IProviderAppointmentProto>|null} [appointments] ProviderSchemaProto appointments
                 * @property {string|null} [providerTimeZone] ProviderSchemaProto providerTimeZone
                 * @property {Array.<space.kenko.proto.IAvailabilityBlockProto>|null} [defaultAvailabilityBlocks] ProviderSchemaProto defaultAvailabilityBlocks
                 */

                /**
                 * Constructs a new ProviderSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderSchemaProto.
                 * @implements IProviderSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderSchemaProto=} [properties] Properties to set
                 */
                function ProviderSchemaProto(properties) {
                    this.services = [];
                    this.appointments = [];
                    this.defaultAvailabilityBlocks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderSchemaProto services.
                 * @member {Array.<space.kenko.proto.IServiceSchemaProto>} services
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.services = $util.emptyArray;

                /**
                 * ProviderSchemaProto appointments.
                 * @member {Array.<space.kenko.proto.IProviderAppointmentProto>} appointments
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.appointments = $util.emptyArray;

                /**
                 * ProviderSchemaProto providerTimeZone.
                 * @member {string} providerTimeZone
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.providerTimeZone = "";

                /**
                 * ProviderSchemaProto defaultAvailabilityBlocks.
                 * @member {Array.<space.kenko.proto.IAvailabilityBlockProto>} defaultAvailabilityBlocks
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.defaultAvailabilityBlocks = $util.emptyArray;

                /**
                 * Creates a new ProviderSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.IProviderSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto instance
                 */
                ProviderSchemaProto.create = function create(properties) {
                    return new ProviderSchemaProto(properties);
                };

                /**
                 * Encodes the specified ProviderSchemaProto message. Does not implicitly {@link space.kenko.proto.ProviderSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.IProviderSchemaProto} message ProviderSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.services != null && message.services.length)
                        for (var i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.ServiceSchemaProto.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.ProviderAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.providerTimeZone != null && Object.hasOwnProperty.call(message, "providerTimeZone"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.providerTimeZone);
                    if (message.defaultAvailabilityBlocks != null && message.defaultAvailabilityBlocks.length)
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i)
                            $root.space.kenko.proto.AvailabilityBlockProto.encode(message.defaultAvailabilityBlocks[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ProviderSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.IProviderSchemaProto} message ProviderSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.ServiceSchemaProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.ProviderAppointmentProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                message.providerTimeZone = reader.string();
                                break;
                            }
                        case 21: {
                                if (!(message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length))
                                    message.defaultAvailabilityBlocks = [];
                                message.defaultAvailabilityBlocks.push($root.space.kenko.proto.AvailabilityBlockProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (var i = 0; i < message.services.length; ++i) {
                            var error = $root.space.kenko.proto.ServiceSchemaProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.ProviderAppointmentProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.providerTimeZone != null && message.hasOwnProperty("providerTimeZone"))
                        if (!$util.isString(message.providerTimeZone))
                            return "providerTimeZone: string expected";
                    if (message.defaultAvailabilityBlocks != null && message.hasOwnProperty("defaultAvailabilityBlocks")) {
                        if (!Array.isArray(message.defaultAvailabilityBlocks))
                            return "defaultAvailabilityBlocks: array expected";
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i) {
                            var error = $root.space.kenko.proto.AvailabilityBlockProto.verify(message.defaultAvailabilityBlocks[i]);
                            if (error)
                                return "defaultAvailabilityBlocks." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ProviderSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto
                 */
                ProviderSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderSchemaProto();
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.ProviderSchemaProto.services: array expected");
                        message.services = [];
                        for (var i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSchemaProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.ServiceSchemaProto.fromObject(object.services[i]);
                        }
                    }
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.ProviderSchemaProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSchemaProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.ProviderAppointmentProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.providerTimeZone != null)
                        message.providerTimeZone = String(object.providerTimeZone);
                    if (object.defaultAvailabilityBlocks) {
                        if (!Array.isArray(object.defaultAvailabilityBlocks))
                            throw TypeError(".space.kenko.proto.ProviderSchemaProto.defaultAvailabilityBlocks: array expected");
                        message.defaultAvailabilityBlocks = [];
                        for (var i = 0; i < object.defaultAvailabilityBlocks.length; ++i) {
                            if (typeof object.defaultAvailabilityBlocks[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSchemaProto.defaultAvailabilityBlocks: object expected");
                            message.defaultAvailabilityBlocks[i] = $root.space.kenko.proto.AvailabilityBlockProto.fromObject(object.defaultAvailabilityBlocks[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.ProviderSchemaProto} message ProviderSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.services = [];
                        object.appointments = [];
                        object.defaultAvailabilityBlocks = [];
                    }
                    if (options.defaults)
                        object.providerTimeZone = "";
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (var j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.ServiceSchemaProto.toObject(message.services[j], options);
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.ProviderAppointmentProto.toObject(message.appointments[j], options);
                    }
                    if (message.providerTimeZone != null && message.hasOwnProperty("providerTimeZone"))
                        object.providerTimeZone = message.providerTimeZone;
                    if (message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length) {
                        object.defaultAvailabilityBlocks = [];
                        for (var j = 0; j < message.defaultAvailabilityBlocks.length; ++j)
                            object.defaultAvailabilityBlocks[j] = $root.space.kenko.proto.AvailabilityBlockProto.toObject(message.defaultAvailabilityBlocks[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ProviderSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderSchemaProto";
                };

                return ProviderSchemaProto;
            })();

            proto.AvailabilityBlockProto = (function() {

                /**
                 * Properties of an AvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @interface IAvailabilityBlockProto
                 * @property {number|null} [dayOfWeek] AvailabilityBlockProto dayOfWeek
                 * @property {space.kenko.proto.ILocalTimeProto|null} [start] AvailabilityBlockProto start
                 * @property {space.kenko.proto.ILocalTimeProto|null} [end] AvailabilityBlockProto end
                 */

                /**
                 * Constructs a new AvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an AvailabilityBlockProto.
                 * @implements IAvailabilityBlockProto
                 * @constructor
                 * @param {space.kenko.proto.IAvailabilityBlockProto=} [properties] Properties to set
                 */
                function AvailabilityBlockProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AvailabilityBlockProto dayOfWeek.
                 * @member {number} dayOfWeek
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 */
                AvailabilityBlockProto.prototype.dayOfWeek = 0;

                /**
                 * AvailabilityBlockProto start.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} start
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 */
                AvailabilityBlockProto.prototype.start = null;

                /**
                 * AvailabilityBlockProto end.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} end
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 */
                AvailabilityBlockProto.prototype.end = null;

                /**
                 * Creates a new AvailabilityBlockProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IAvailabilityBlockProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto instance
                 */
                AvailabilityBlockProto.create = function create(properties) {
                    return new AvailabilityBlockProto(properties);
                };

                /**
                 * Encodes the specified AvailabilityBlockProto message. Does not implicitly {@link space.kenko.proto.AvailabilityBlockProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AvailabilityBlockProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.start, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.end, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AvailabilityBlockProto message, length delimited. Does not implicitly {@link space.kenko.proto.AvailabilityBlockProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AvailabilityBlockProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AvailabilityBlockProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AvailabilityBlockProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.AvailabilityBlockProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.dayOfWeek = reader.int32();
                                break;
                            }
                        case 2: {
                                message.start = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.end = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AvailabilityBlockProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AvailabilityBlockProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AvailabilityBlockProto message.
                 * @function verify
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AvailabilityBlockProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        if (!$util.isInteger(message.dayOfWeek))
                            return "dayOfWeek: integer expected";
                    if (message.start != null && message.hasOwnProperty("start")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.start);
                        if (error)
                            return "start." + error;
                    }
                    if (message.end != null && message.hasOwnProperty("end")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.end);
                        if (error)
                            return "end." + error;
                    }
                    return null;
                };

                /**
                 * Creates an AvailabilityBlockProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto
                 */
                AvailabilityBlockProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.AvailabilityBlockProto)
                        return object;
                    var message = new $root.space.kenko.proto.AvailabilityBlockProto();
                    if (object.dayOfWeek != null)
                        message.dayOfWeek = object.dayOfWeek | 0;
                    if (object.start != null) {
                        if (typeof object.start !== "object")
                            throw TypeError(".space.kenko.proto.AvailabilityBlockProto.start: object expected");
                        message.start = $root.space.kenko.proto.LocalTimeProto.fromObject(object.start);
                    }
                    if (object.end != null) {
                        if (typeof object.end !== "object")
                            throw TypeError(".space.kenko.proto.AvailabilityBlockProto.end: object expected");
                        message.end = $root.space.kenko.proto.LocalTimeProto.fromObject(object.end);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AvailabilityBlockProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.AvailabilityBlockProto} message AvailabilityBlockProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AvailabilityBlockProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.dayOfWeek = 0;
                        object.start = null;
                        object.end = null;
                    }
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        object.dayOfWeek = message.dayOfWeek;
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = $root.space.kenko.proto.LocalTimeProto.toObject(message.start, options);
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = $root.space.kenko.proto.LocalTimeProto.toObject(message.end, options);
                    return object;
                };

                /**
                 * Converts this AvailabilityBlockProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AvailabilityBlockProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AvailabilityBlockProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AvailabilityBlockProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.AvailabilityBlockProto";
                };

                return AvailabilityBlockProto;
            })();

            proto.InvitationProtos = (function() {

                /**
                 * Properties of an InvitationProtos.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProtos
                 * @property {Array.<space.kenko.proto.IInvitationProto>|null} [invitations] InvitationProtos invitations
                 */

                /**
                 * Constructs a new InvitationProtos.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProtos.
                 * @implements IInvitationProtos
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 */
                function InvitationProtos(properties) {
                    this.invitations = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProtos invitations.
                 * @member {Array.<space.kenko.proto.IInvitationProto>} invitations
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 */
                InvitationProtos.prototype.invitations = $util.emptyArray;

                /**
                 * Creates a new InvitationProtos instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos instance
                 */
                InvitationProtos.create = function create(properties) {
                    return new InvitationProtos(properties);
                };

                /**
                 * Encodes the specified InvitationProtos message. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invitations != null && message.invitations.length)
                        for (var i = 0; i < message.invitations.length; ++i)
                            $root.space.kenko.proto.InvitationProto.encode(message.invitations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProtos message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProtos();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.invitations && message.invitations.length))
                                    message.invitations = [];
                                message.invitations.push($root.space.kenko.proto.InvitationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProtos message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProtos.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invitations != null && message.hasOwnProperty("invitations")) {
                        if (!Array.isArray(message.invitations))
                            return "invitations: array expected";
                        for (var i = 0; i < message.invitations.length; ++i) {
                            var error = $root.space.kenko.proto.InvitationProto.verify(message.invitations[i]);
                            if (error)
                                return "invitations." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an InvitationProtos message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 */
                InvitationProtos.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProtos)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProtos();
                    if (object.invitations) {
                        if (!Array.isArray(object.invitations))
                            throw TypeError(".space.kenko.proto.InvitationProtos.invitations: array expected");
                        message.invitations = [];
                        for (var i = 0; i < object.invitations.length; ++i) {
                            if (typeof object.invitations[i] !== "object")
                                throw TypeError(".space.kenko.proto.InvitationProtos.invitations: object expected");
                            message.invitations[i] = $root.space.kenko.proto.InvitationProto.fromObject(object.invitations[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProtos message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.InvitationProtos} message InvitationProtos
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProtos.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.invitations = [];
                    if (message.invitations && message.invitations.length) {
                        object.invitations = [];
                        for (var j = 0; j < message.invitations.length; ++j)
                            object.invitations[j] = $root.space.kenko.proto.InvitationProto.toObject(message.invitations[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this InvitationProtos to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProtos.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProtos
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProtos.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProtos";
                };

                return InvitationProtos;
            })();

            proto.InvitationProto = (function() {

                /**
                 * Properties of an InvitationProto.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProto
                 * @property {string|null} [inviteCode] InvitationProto inviteCode
                 * @property {number|Long|null} [generatedTimestamp] InvitationProto generatedTimestamp
                 */

                /**
                 * Constructs a new InvitationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProto.
                 * @implements IInvitationProto
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 */
                function InvitationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProto inviteCode.
                 * @member {string} inviteCode
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.inviteCode = "";

                /**
                 * InvitationProto generatedTimestamp.
                 * @member {number|Long} generatedTimestamp
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.generatedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new InvitationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto instance
                 */
                InvitationProto.create = function create(properties) {
                    return new InvitationProto(properties);
                };

                /**
                 * Encodes the specified InvitationProto message. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
                    if (message.generatedTimestamp != null && Object.hasOwnProperty.call(message, "generatedTimestamp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedTimestamp);
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProto message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.generatedTimestamp = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        if (!$util.isString(message.inviteCode))
                            return "inviteCode: string expected";
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (!$util.isInteger(message.generatedTimestamp) && !(message.generatedTimestamp && $util.isInteger(message.generatedTimestamp.low) && $util.isInteger(message.generatedTimestamp.high)))
                            return "generatedTimestamp: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an InvitationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 */
                InvitationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProto)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProto();
                    if (object.inviteCode != null)
                        message.inviteCode = String(object.inviteCode);
                    if (object.generatedTimestamp != null)
                        if ($util.Long)
                            (message.generatedTimestamp = $util.Long.fromValue(object.generatedTimestamp)).unsigned = false;
                        else if (typeof object.generatedTimestamp === "string")
                            message.generatedTimestamp = parseInt(object.generatedTimestamp, 10);
                        else if (typeof object.generatedTimestamp === "number")
                            message.generatedTimestamp = object.generatedTimestamp;
                        else if (typeof object.generatedTimestamp === "object")
                            message.generatedTimestamp = new $util.LongBits(object.generatedTimestamp.low >>> 0, object.generatedTimestamp.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.InvitationProto} message InvitationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.inviteCode = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedTimestamp = options.longs === String ? "0" : 0;
                    }
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        object.inviteCode = message.inviteCode;
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (typeof message.generatedTimestamp === "number")
                            object.generatedTimestamp = options.longs === String ? String(message.generatedTimestamp) : message.generatedTimestamp;
                        else
                            object.generatedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.generatedTimestamp) : options.longs === Number ? new $util.LongBits(message.generatedTimestamp.low >>> 0, message.generatedTimestamp.high >>> 0).toNumber() : message.generatedTimestamp;
                    return object;
                };

                /**
                 * Converts this InvitationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProto";
                };

                return InvitationProto;
            })();

            proto.UserProfileSchemaProto = (function() {

                /**
                 * Properties of a UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileSchemaProto
                 * @property {Array.<space.kenko.proto.IClientAppointmentSchemaProto>|null} [appointments] UserProfileSchemaProto appointments
                 * @property {string|null} [name] UserProfileSchemaProto name
                 * @property {space.kenko.proto.IWellnessSurveySchemaProto|null} [wellnessSurvey] UserProfileSchemaProto wellnessSurvey
                 */

                /**
                 * Constructs a new UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileSchemaProto.
                 * @implements IUserProfileSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 */
                function UserProfileSchemaProto(properties) {
                    this.appointments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileSchemaProto appointments.
                 * @member {Array.<space.kenko.proto.IClientAppointmentSchemaProto>} appointments
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.appointments = $util.emptyArray;

                /**
                 * UserProfileSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.name = "";

                /**
                 * UserProfileSchemaProto wellnessSurvey.
                 * @member {space.kenko.proto.IWellnessSurveySchemaProto|null|undefined} wellnessSurvey
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.wellnessSurvey = null;

                /**
                 * Creates a new UserProfileSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto instance
                 */
                UserProfileSchemaProto.create = function create(properties) {
                    return new UserProfileSchemaProto(properties);
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.ClientAppointmentSchemaProto.encode(message.appointments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.wellnessSurvey != null && Object.hasOwnProperty.call(message, "wellnessSurvey"))
                        $root.space.kenko.proto.WellnessSurveySchemaProto.encode(message.wellnessSurvey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.ClientAppointmentSchemaProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.ClientAppointmentSchemaProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey")) {
                        var error = $root.space.kenko.proto.WellnessSurveySchemaProto.verify(message.wellnessSurvey);
                        if (error)
                            return "wellnessSurvey." + error;
                    }
                    return null;
                };

                /**
                 * Creates a UserProfileSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 */
                UserProfileSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.ClientAppointmentSchemaProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.wellnessSurvey != null) {
                        if (typeof object.wellnessSurvey !== "object")
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.wellnessSurvey: object expected");
                        message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.fromObject(object.wellnessSurvey);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.UserProfileSchemaProto} message UserProfileSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.appointments = [];
                    if (options.defaults) {
                        object.name = "";
                        object.wellnessSurvey = null;
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.ClientAppointmentSchemaProto.toObject(message.appointments[j], options);
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey"))
                        object.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.toObject(message.wellnessSurvey, options);
                    return object;
                };

                /**
                 * Converts this UserProfileSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileSchemaProto";
                };

                return UserProfileSchemaProto;
            })();

            proto.WellnessSurveySchemaProto = (function() {

                /**
                 * Properties of a WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IWellnessSurveySchemaProto
                 * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveySchemaProto holisticHealthInterests
                 * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveySchemaProto familiarityWithEasternDietaryPractices
                 * @property {Array.<string>|null} [fitnessGoals] WellnessSurveySchemaProto fitnessGoals
                 * @property {string|null} [currentSkincareRoutine] WellnessSurveySchemaProto currentSkincareRoutine
                 * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveySchemaProto hairRelatedConcerns
                 */

                /**
                 * Constructs a new WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a WellnessSurveySchemaProto.
                 * @implements IWellnessSurveySchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 */
                function WellnessSurveySchemaProto(properties) {
                    this.holisticHealthInterests = [];
                    this.fitnessGoals = [];
                    this.hairRelatedConcerns = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WellnessSurveySchemaProto holisticHealthInterests.
                 * @member {Array.<string>} holisticHealthInterests
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.holisticHealthInterests = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto familiarityWithEasternDietaryPractices.
                 * @member {string} familiarityWithEasternDietaryPractices
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.familiarityWithEasternDietaryPractices = "";

                /**
                 * WellnessSurveySchemaProto fitnessGoals.
                 * @member {Array.<string>} fitnessGoals
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.fitnessGoals = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto currentSkincareRoutine.
                 * @member {string} currentSkincareRoutine
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.currentSkincareRoutine = "";

                /**
                 * WellnessSurveySchemaProto hairRelatedConcerns.
                 * @member {Array.<string>} hairRelatedConcerns
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.hairRelatedConcerns = $util.emptyArray;

                /**
                 * Creates a new WellnessSurveySchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto instance
                 */
                WellnessSurveySchemaProto.create = function create(properties) {
                    return new WellnessSurveySchemaProto(properties);
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
                    if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
                    if (message.fitnessGoals != null && message.fitnessGoals.length)
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
                    if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
                    if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
                    return writer;
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                                    message.holisticHealthInterests = [];
                                message.holisticHealthInterests.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.familiarityWithEasternDietaryPractices = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fitnessGoals && message.fitnessGoals.length))
                                    message.fitnessGoals = [];
                                message.fitnessGoals.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.currentSkincareRoutine = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                                    message.hairRelatedConcerns = [];
                                message.hairRelatedConcerns.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WellnessSurveySchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WellnessSurveySchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
                        if (!Array.isArray(message.holisticHealthInterests))
                            return "holisticHealthInterests: array expected";
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            if (!$util.isString(message.holisticHealthInterests[i]))
                                return "holisticHealthInterests: string[] expected";
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                            return "familiarityWithEasternDietaryPractices: string expected";
                    if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
                        if (!Array.isArray(message.fitnessGoals))
                            return "fitnessGoals: array expected";
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            if (!$util.isString(message.fitnessGoals[i]))
                                return "fitnessGoals: string[] expected";
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        if (!$util.isString(message.currentSkincareRoutine))
                            return "currentSkincareRoutine: string expected";
                    if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
                        if (!Array.isArray(message.hairRelatedConcerns))
                            return "hairRelatedConcerns: array expected";
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            if (!$util.isString(message.hairRelatedConcerns[i]))
                                return "hairRelatedConcerns: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a WellnessSurveySchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 */
                WellnessSurveySchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.WellnessSurveySchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    if (object.holisticHealthInterests) {
                        if (!Array.isArray(object.holisticHealthInterests))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.holisticHealthInterests: array expected");
                        message.holisticHealthInterests = [];
                        for (var i = 0; i < object.holisticHealthInterests.length; ++i)
                            message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
                    }
                    if (object.familiarityWithEasternDietaryPractices != null)
                        message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
                    if (object.fitnessGoals) {
                        if (!Array.isArray(object.fitnessGoals))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.fitnessGoals: array expected");
                        message.fitnessGoals = [];
                        for (var i = 0; i < object.fitnessGoals.length; ++i)
                            message.fitnessGoals[i] = String(object.fitnessGoals[i]);
                    }
                    if (object.currentSkincareRoutine != null)
                        message.currentSkincareRoutine = String(object.currentSkincareRoutine);
                    if (object.hairRelatedConcerns) {
                        if (!Array.isArray(object.hairRelatedConcerns))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.hairRelatedConcerns: array expected");
                        message.hairRelatedConcerns = [];
                        for (var i = 0; i < object.hairRelatedConcerns.length; ++i)
                            message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WellnessSurveySchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.WellnessSurveySchemaProto} message WellnessSurveySchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WellnessSurveySchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.holisticHealthInterests = [];
                        object.fitnessGoals = [];
                        object.hairRelatedConcerns = [];
                    }
                    if (options.defaults) {
                        object.familiarityWithEasternDietaryPractices = "";
                        object.currentSkincareRoutine = "";
                    }
                    if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
                        object.holisticHealthInterests = [];
                        for (var j = 0; j < message.holisticHealthInterests.length; ++j)
                            object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
                    if (message.fitnessGoals && message.fitnessGoals.length) {
                        object.fitnessGoals = [];
                        for (var j = 0; j < message.fitnessGoals.length; ++j)
                            object.fitnessGoals[j] = message.fitnessGoals[j];
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        object.currentSkincareRoutine = message.currentSkincareRoutine;
                    if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
                        object.hairRelatedConcerns = [];
                        for (var j = 0; j < message.hairRelatedConcerns.length; ++j)
                            object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
                    }
                    return object;
                };

                /**
                 * Converts this WellnessSurveySchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WellnessSurveySchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WellnessSurveySchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WellnessSurveySchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.WellnessSurveySchemaProto";
                };

                return WellnessSurveySchemaProto;
            })();

            proto.ClientAppointmentSchemaProto = (function() {

                /**
                 * Properties of a ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IClientAppointmentSchemaProto
                 * @property {string|null} [appointmentId] ClientAppointmentSchemaProto appointmentId
                 * @property {number|Long|null} [startTimeMs] ClientAppointmentSchemaProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] ClientAppointmentSchemaProto endTimeMs
                 * @property {string|null} [serviceName] ClientAppointmentSchemaProto serviceName
                 * @property {string|null} [providerName] ClientAppointmentSchemaProto providerName
                 */

                /**
                 * Constructs a new ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ClientAppointmentSchemaProto.
                 * @implements IClientAppointmentSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 */
                function ClientAppointmentSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClientAppointmentSchemaProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.appointmentId = "";

                /**
                 * ClientAppointmentSchemaProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto serviceName.
                 * @member {string} serviceName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.serviceName = "";

                /**
                 * ClientAppointmentSchemaProto providerName.
                 * @member {string} providerName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerName = "";

                /**
                 * Creates a new ClientAppointmentSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto instance
                 */
                ClientAppointmentSchemaProto.create = function create(properties) {
                    return new ClientAppointmentSchemaProto(properties);
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
                    return writer;
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.serviceName = reader.string();
                                break;
                            }
                        case 5: {
                                message.providerName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClientAppointmentSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClientAppointmentSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        if (!$util.isString(message.serviceName))
                            return "serviceName: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    return null;
                };

                /**
                 * Creates a ClientAppointmentSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 */
                ClientAppointmentSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ClientAppointmentSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.serviceName != null)
                        message.serviceName = String(object.serviceName);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    return message;
                };

                /**
                 * Creates a plain object from a ClientAppointmentSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.ClientAppointmentSchemaProto} message ClientAppointmentSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClientAppointmentSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.serviceName = "";
                        object.providerName = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        object.serviceName = message.serviceName;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    return object;
                };

                /**
                 * Converts this ClientAppointmentSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClientAppointmentSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ClientAppointmentSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClientAppointmentSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ClientAppointmentSchemaProto";
                };

                return ClientAppointmentSchemaProto;
            })();

            proto.SettingsProto = (function() {

                /**
                 * Properties of a SettingsProto.
                 * @memberof space.kenko.proto
                 * @interface ISettingsProto
                 * @property {string|null} [llmModel] SettingsProto llmModel
                 * @property {string|null} [llmSystemPrompt] SettingsProto llmSystemPrompt
                 * @property {string|null} [llmBackend] SettingsProto llmBackend
                 * @property {string|null} [providerImagesBucket] SettingsProto providerImagesBucket
                 */

                /**
                 * Constructs a new SettingsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a SettingsProto.
                 * @implements ISettingsProto
                 * @constructor
                 * @param {space.kenko.proto.ISettingsProto=} [properties] Properties to set
                 */
                function SettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SettingsProto llmModel.
                 * @member {string} llmModel
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmModel = "";

                /**
                 * SettingsProto llmSystemPrompt.
                 * @member {string} llmSystemPrompt
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmSystemPrompt = "";

                /**
                 * SettingsProto llmBackend.
                 * @member {string} llmBackend
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmBackend = "";

                /**
                 * SettingsProto providerImagesBucket.
                 * @member {string} providerImagesBucket
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.providerImagesBucket = "";

                /**
                 * Creates a new SettingsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto instance
                 */
                SettingsProto.create = function create(properties) {
                    return new SettingsProto(properties);
                };

                /**
                 * Encodes the specified SettingsProto message. Does not implicitly {@link space.kenko.proto.SettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.llmModel != null && Object.hasOwnProperty.call(message, "llmModel"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.llmModel);
                    if (message.llmSystemPrompt != null && Object.hasOwnProperty.call(message, "llmSystemPrompt"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.llmSystemPrompt);
                    if (message.llmBackend != null && Object.hasOwnProperty.call(message, "llmBackend"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.llmBackend);
                    if (message.providerImagesBucket != null && Object.hasOwnProperty.call(message, "providerImagesBucket"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.providerImagesBucket);
                    return writer;
                };

                /**
                 * Encodes the specified SettingsProto message, length delimited. Does not implicitly {@link space.kenko.proto.SettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.SettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.llmModel = reader.string();
                                break;
                            }
                        case 2: {
                                message.llmSystemPrompt = reader.string();
                                break;
                            }
                        case 3: {
                                message.llmBackend = reader.string();
                                break;
                            }
                        case 4: {
                                message.providerImagesBucket = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SettingsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.llmModel != null && message.hasOwnProperty("llmModel"))
                        if (!$util.isString(message.llmModel))
                            return "llmModel: string expected";
                    if (message.llmSystemPrompt != null && message.hasOwnProperty("llmSystemPrompt"))
                        if (!$util.isString(message.llmSystemPrompt))
                            return "llmSystemPrompt: string expected";
                    if (message.llmBackend != null && message.hasOwnProperty("llmBackend"))
                        if (!$util.isString(message.llmBackend))
                            return "llmBackend: string expected";
                    if (message.providerImagesBucket != null && message.hasOwnProperty("providerImagesBucket"))
                        if (!$util.isString(message.providerImagesBucket))
                            return "providerImagesBucket: string expected";
                    return null;
                };

                /**
                 * Creates a SettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 */
                SettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.SettingsProto)
                        return object;
                    var message = new $root.space.kenko.proto.SettingsProto();
                    if (object.llmModel != null)
                        message.llmModel = String(object.llmModel);
                    if (object.llmSystemPrompt != null)
                        message.llmSystemPrompt = String(object.llmSystemPrompt);
                    if (object.llmBackend != null)
                        message.llmBackend = String(object.llmBackend);
                    if (object.providerImagesBucket != null)
                        message.providerImagesBucket = String(object.providerImagesBucket);
                    return message;
                };

                /**
                 * Creates a plain object from a SettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.SettingsProto} message SettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.llmModel = "";
                        object.llmSystemPrompt = "";
                        object.llmBackend = "";
                        object.providerImagesBucket = "";
                    }
                    if (message.llmModel != null && message.hasOwnProperty("llmModel"))
                        object.llmModel = message.llmModel;
                    if (message.llmSystemPrompt != null && message.hasOwnProperty("llmSystemPrompt"))
                        object.llmSystemPrompt = message.llmSystemPrompt;
                    if (message.llmBackend != null && message.hasOwnProperty("llmBackend"))
                        object.llmBackend = message.llmBackend;
                    if (message.providerImagesBucket != null && message.hasOwnProperty("providerImagesBucket"))
                        object.providerImagesBucket = message.providerImagesBucket;
                    return object;
                };

                /**
                 * Converts this SettingsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SettingsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.SettingsProto";
                };

                return SettingsProto;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

module.exports = $root;
